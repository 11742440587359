/* eslint-disable react/prop-types */
import React from "react";
// import propTypes from "prop-types";
import {
  SliceFullBleedImage,
  SliceFullBleedVideo,
  SliceImageCopyQuote,
  SliceImageCopyIcons,
  SliceLogoGrid,
  SliceImageCopyLinks,
} from "@molecules";
import Timeline from "../modules/Timeline/Timeline";

const Slices = ({ slices }) => {
  return (
    <>
      {slices &&
        slices.map((block, i) => {
          if (block.type) {
            switch (block.type) {
              case "full_bleed_image":
                return <SliceFullBleedImage {...block} />;
              case "full_bleed_video":
                return <SliceFullBleedVideo first={i === 0} {...block} />;
              case "image___copy_quote":
                return <SliceImageCopyQuote {...block} />;
              case "image___copy___icons":
                return <SliceImageCopyIcons {...block} />;
              case "img___copy_w__links":
                return <SliceImageCopyLinks {...block} />;
              case "logo_grid":
                return <SliceLogoGrid {...block} />;
              case "timeline":
                return <Timeline items={block.timeline} />;
              default:
                return <div />;
            }
          }
          return null;
        })}
    </>
  );
};

Slices.defaultProps = {};

Slices.propTypes = {};

export default Slices;
