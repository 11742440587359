import { useState, useRef, useLayoutEffect } from "react";
import ResizeObserverPolyfill from "resize-observer-polyfill";

const hasResizeObserver =
  typeof window !== "undefined" && !!window.ResizeObserver;

const ResizeObserver = hasResizeObserver
  ? window.ResizeObserver
  : ResizeObserverPolyfill;

function useDimensions() {
  const observer = useRef();
  const el = useRef();
  const [dimensions, setDimensions] = useState({});

  const handler = entries => {
    setDimensions(entries[0].contentRect);
  };

  useLayoutEffect(() => {
    observer.current = new ResizeObserver(handler);
    observer.current.observe(el.current);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return [el, dimensions];
}

export default useDimensions;
