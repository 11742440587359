/* eslint-disable react/prop-types */
import React from "react";
// import propTypes from "prop-types";
import {
  CbCopy,
  CbImage,
  CbVideo,
  CbQuote,
  CbQuoteWithHeading,
  CbImageWithAccordion,
  CbBreakoutBox,
  CbCarousel,
} from "@molecules";

const ContentBuilder = ({ blocks }) => {
  return (
    <>
      {blocks &&
        blocks.map((block, i) => {
          if (block.type) {
            switch (block.type) {
              case "copy":
                return <CbCopy {...block} key={i} />;
              case "image":
                return <CbImage {...block} key={i} />;
              case "video_embed":
                return <CbVideo {...block} key={i} />;
              case "pull_quote":
                return <CbQuote {...block} key={i} />;
              case "quote_with_heading":
                return <CbQuoteWithHeading {...block} key={i} />;
              case "image_with_accordian":
                return <CbImageWithAccordion {...block} key={i} />;
              case "breakout_box":
                return <CbBreakoutBox {...block} key={i} />;
              case "carousel":
                return <CbCarousel {...block} key={i} />;
              default:
                return null;
            }
          }
          return null;
        })}
    </>
  );
};

ContentBuilder.defaultProps = {};

ContentBuilder.propTypes = {};

export default ContentBuilder;
