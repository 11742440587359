import React from "react";
import { Container, Text } from "@base";
import { Link, Icon } from "@atoms";
import { EmailSignUp } from "@molecules";
import { useFooterData } from "@staticQueries";

const Footer = () => {
  const {
    menu,
    // contact,
    social,
    policyPages,
    // backgroundImage,
    copyrightText,
    emailHeading,
    emailCta,
  } = useFooterData();

  return (
    <footer className="relative bg-gray">
      {/* <Image {...backgroundImage} showLQIP={false} lazyLoad={false} />

      <Container className="relative z-20 flex flex-col lg:flex-row items-center lg:items-start lg:justify-between mb-8 sm:mb-12">
        <Link to="/" className="mt-4">
          <Icon name="jobyLogoDark" />
        </Link>

        <nav className="flex flex-col py-6 lg:py-0 px-6 text-center lg:text-left">
          <Text
            tag="h3"
            options={{ size: "text-sm" }}
            className="my-2 text-white"
          >
            {menu.heading}
          </Text>

          {menu.items.map(item => (
            <div key={item.uid} className="my-3">
              <Link
                to={item.url}
                className="hover:opacity-75 transition duration-166 ease-in-out"
              >
                <Text
                  tag="span"
                  use="button"
                  options={{ size: "text-xs" }}
                  className="text-white"
                >
                  {item.title}
                </Text>
              </Link>
            </div>
          ))}
        </nav>

        <div className="flex flex-col px-6 text-center lg:text-left">
          <Text
            tag="h3"
            options={{ size: "text-sm" }}
            className="my-2 text-white"
          >
            {contact.heading}
          </Text>

          <Link to={`tel:${contact.phone}`} target="_blank" className="my-3">
            <Text
              tag="span"
              use="button"
              options={{ size: "text-xs" }}
              className="text-white hover:opacity-75 transition duration-166 ease-in-out"
            >
              {contact.phone}
            </Text>
          </Link>

          <Link to={`mailto:${contact.email}`} target="_blank" className="my-3">
            <Text
              tag="span"
              use="button"
              options={{ size: "text-xs" }}
              className="text-white hover:opacity-75 transition duration-166 ease-in-out"
            >
              {contact.email}
            </Text>
          </Link>

          <div className="flex justify-center lg:justify-start mt-4">
            {social.map(item => (
              <SocialIcon
                key={item.uid}
                name={item.service}
                url={item.url}
                className="mx-3 lg:ml-0 lg:mr-4"
              />
            ))}
          </div>
        </div>
      </Container> */}

      {/* sitemap + widgets */}
      <div className="bg-gray-lighter">
        <Container>
          <div className="flex text-gray-dark py-6 flex-wrap items-end lg:justify-between -mx-3 lg:-mx-6">
            {/* nav */}
            <div className="w-full my-6 sm:w-2/7 lg:w-auto px-3 lg:px-6 order-2 sm:order-1">
              <ul className="grid grid-cols-2 gap-6">
                {menu.map(item => {
                  return (
                    <li className="text-sm">
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* email signup */}
            <div className="w-full my-6 max-w-md sm:w-3/7 lg:w-auto px-3 lg:px-6 order-1 sm:order-2">
              <Text tag="h4" className="mb-1">
                {emailHeading || "Subscribe for Joby Updates"}
              </Text>
              <div>
                <EmailSignUp cta={emailCta} />
              </div>
            </div>
            {/* social */}
            <div className="w-full my-6 sm:w-2/7 lg:w-auto px-3 lg:px-6 flex flex-col sm:items-end sm:justify-end order-3">
              <ul className="flex -mx-1">
                {social.map(link => {
                  return (
                    <li className="px-1">
                      <Link to={link.url} target="_blank">
                        <Icon
                          name={link.platform}
                          className="w-6 h-6 p-1 bg-gray text-gray-lighter hover:text-white hover:bg-gray-dark transition duration-333 rounded-full flex items-center justify-center"
                        />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Container>
      </div>
      {/* post-footer */}
      <div className="relative z-20 text-white py-4 leading-1.5">
        <Container
          size="md"
          className="flex flex-col sm:flex-row sm:justify-center items-center text-center"
        >
          <div className="flex justify-center sm:justify-start mt-2 sm:mt-0 px-4 text-white">
            <Icon name="jobyIcon" fitHeight className="h-8" />
          </div>
          <div className="flex justify-center sm:justify-start sm:-mr-4 sm:ml-2">
            {policyPages.map(item => (
              <React.Fragment key={item.uid}>
                <div className="mx-4 my-2 sm:my-0">
                  <Link to={item.url} className="block">
                    <Text
                      tag="span"
                      options={{ size: "text-xs" }}
                      className="block hover:opacity-75 transition duration-166 ease-in-out"
                    >
                      {item.title}
                    </Text>
                  </Link>
                </div>
              </React.Fragment>
            ))}
          </div>

          <div className="px-4">
            <Text
              tag="span"
              options={{ size: "text-xs" }}
              className="block mb-2 sm:mb-0 sm:mx-4"
            >
              {"\u00A9"} {new Date().getFullYear()} {copyrightText}
            </Text>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
