const reducer = (state, action) => {
  switch (action.type) {
    case "playVideo": {
      return {
        ...state,
        url: action.url,
      };
    }

    case "closeVideo": {
      return {
        ...state,
        url: false,
      };
    }

    default:
      return state;
  }
};

const initialState = {
  url: false,
};

export default {
  reducer,
  initialState,
};
