import React from "react";
import PropTypes from "prop-types";
import { Text as CosmicText } from "@designbycosmic/cosmic-react-common-components";

// Pass default options for common text elements accross the various
// style attributes: font, size, style (bold/italic), line-height, letter-spacing

export const config = {
  typography: {
    h1: {
      size: "text-1-5xl sm:text-3xl md:text-4xl lg:text-6xl font-extralight",
      lineHeight: "leading-1.2",
      font: "font-sans",
    },
    h2: {
      size: "text-xl sm:text-2xl",
      lineHeight: "leading-1.2",
      font: "font-sans",
      // weight: "font-bold",
    },
    h3: {
      size: "text-lg sm:text-xl",
      lineHeight: "leading-1.2",
      font: "font-sans",
      // weight: "font-bold",
    },
    h4: {
      size: "text-base sm:text-lg",
      lineHeight: "leading-1.2",
      font: "font-sans",
      // weight: "font-bold",
    },
    p: {
      size: "text-base",
      font: "font-sans",
      lineHeight: "leading-1.5",
    },
    li: {
      size: "text-base",
      font: "font-sans",
      lineHeight: "leading-1.5",
    },
    label: {
      size: "text-sm",
      font: "font-sans",
      lineHeight: "leading-1",
    },
    button: {
      size: "text-sm",
      font: "font-sans",
      weight: "font-semi-bold",
    },
    span: {},
  },
};

const Text = ({ children, className, style, ...textProps }) => {
  if (!children) {
    return null;
  }

  return (
    <CosmicText
      className={`transition duration-333 ${className}`}
      style={style}
      config={config}
      preserveWidows
      {...textProps}
    >
      {children}
    </CosmicText>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Text.defaultProps = {
  className: "",
  style: {},
};

export default Text;
