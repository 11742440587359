import React from "react";
import PropTypes from "prop-types";
import tinyColor from "tinycolor2";
import { useAppState } from "@state/appState";
import { useDimensions } from "@hooks";
import { getTailwindColor } from "@utils";

const VimeoBackgroundVideo = ({
  videoId,
  ratio,
  disableOnTouchDevice,
  overlayColor,
  overlayAlpha,
  className,
  style,
}) => {
  const [{ layout }] = useAppState();
  const [el, dimensions] = useDimensions();
  const { width: elWidth = 0, height: elHeight = 0 } = dimensions || {};

  let width = elWidth;
  let height = width / ratio;
  if (height < elHeight) {
    height = elHeight;
    width = height * ratio;
  }

  const overlay = tinyColor(overlayColor || getTailwindColor("black.default"))
    .setAlpha(overlayAlpha)
    .toString();

  return (
    <div
      ref={el}
      className={`absolute inset-0 overflow-hidden ${className}`}
      style={style}
    >
      {!(layout.isTouchDevice && disableOnTouchDevice) && (
        <iframe
          title="background video"
          src={`https://player.vimeo.com/video/${videoId}?background=1`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          className="absolute"
          style={{
            width,
            height,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}

      <div className="absolute inset-0 z-10" style={{ background: overlay }} />
    </div>
  );
};

VimeoBackgroundVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
  disableOnTouchDevice: PropTypes.bool,
  overlayColor: PropTypes.string,
  overlayAlpha: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

VimeoBackgroundVideo.defaultProps = {
  disableOnTouchDevice: false,
  overlayColor: null,
  overlayAlpha: 0.6,
  className: "",
  style: {},
};

export default VimeoBackgroundVideo;
