/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container, Section, Image } from "@base";
import { Carousel } from "@molecules";

const CbCarousel = ({ slides }) => {
  return (
    <Section size="sm" className="bg-black my-10">
      <Container size="content">
        <Carousel>
          {slides.map((slide, i) => {
            return (
              <div className="max-w-md w-full mx-auto" key={i}>
                <Image
                  {...slide.image}
                  aspectratio="9:5.62
                  "
                  fitHeight
                  caption={slide?.caption}
                  captionClasses="text-white"
                  placeholder={false}
                />
              </div>
            );
          })}
        </Carousel>
      </Container>
    </Section>
  );
};

CbCarousel.defaultProps = {};

CbCarousel.propTypes = {};

export default CbCarousel;
