import tinyColor from "tinycolor2";
import getTailwindUtility from "./getTailwindUtility";

const colors = getTailwindUtility("colors");

const getTailwindColor = (color, asTinyColor) => {
  let parsedColor;

  if (typeof colors[color] === "string") {
    parsedColor = colors[color];
  }

  if (typeof color === "string") {
    const split = color.split(".");

    if (split.length > 1) {
      parsedColor = split.reduce((obj, key) => obj[key], colors);
    }
  }

  if (asTinyColor) {
    return tinyColor(parsedColor);
  }

  return parsedColor;
};

export default getTailwindColor;
