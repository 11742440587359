import React, { useRef } from "react";
import PropTypes from "prop-types";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import { gsap, Circ } from "gsap";

/**
 *
 * `StaggerIn` animates child elements in a staggered sequence.
 * NOTE: Child elements must be able to receive an apply a `style` prop
 * for the staggered animation to work properly.
 *
 */

const StaggerIn = ({ children, className }) => {
  const el = useRef();

  const onEnter = () => {
    if (el.current) {
      const selector = el.current.querySelectorAll(".StaggerIn__child");
      gsap.to(selector, {
        duration: 1,
        opacity: 1,
        y: 0,
        stagger: {
          amount: (1 / 12) * children.length,
        },
        ease: Circ.easeOut,
        onComplete: () => {
          // make elements have transform:none because we allow embedded modals
          // inside children, which are position:fixed, and these two styles
          // don't play nice:
          // https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
          for (let i = 0; i < selector.length; i += 1) {
            selector[i].style.transform = "none";
          }
        },
      });
    }
  };

  const items = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      className: `StaggerIn__child ${child.props.className}`,
      style: {
        ...child.props.style,
        transform: "translateY(5rem)",
        opacity: 0,
      },
    })
  );

  return (
    <div ref={el} className="StaggerIn">
      <InView
        onEnter={onEnter}
        observerOptions={{
          threshold: 0.01,
        }}
        unobserveAfterEntry
        className={className}
      >
        {items}
      </InView>
    </div>
  );
};

StaggerIn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

StaggerIn.defaultProps = {
  className: "",
};

export default React.memo(StaggerIn);
