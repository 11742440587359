import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query NotFoundPageQuery {
    prismicNotFoundPage {
      id
      uid
      data {
        hero {
          heading
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { uid, hero } = response.prismicNotFoundPage.data;

  return {
    uid,
    hero: hero[0],
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
