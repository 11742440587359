/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container, RichText } from "@base";

const CbCopy = ({ content }) => {
  return (
    <Container size="article">
      <div className="my-10">
        <RichText html={content} className="article-rich-text" article />
      </div>
    </Container>
  );
};

CbCopy.defaultProps = {};

CbCopy.propTypes = {};

export default CbCopy;
