/* eslint-disable import/prefer-default-export */
/* eslint react/prop-types: 0 */

import React from "react";
import "./src/pcss/index.pcss";
import wrapWithState from "@state/wrapWithState";
import { gsap } from "gsap";
import { DrawSVGPlugin, MorphSVGPlugin, ScrollToPlugin } from "@utils";

import { LayoutUpdater } from "@layout";
import {
  AnnouncementBanner,
  GdprBanner,
  Header,
  MobileNav,
  PreviewBanner,
  VideoModal,
} from "@organisms";

// register extra GSAP plugins
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(ScrollToPlugin);

export const wrapRootElement = wrapWithState;

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  return (
    <>
      <LayoutUpdater />
      <PreviewBanner />
      <AnnouncementBanner />
      <GdprBanner />
      <MobileNav />
      <Header currentPath={location.pathname} />
      {element}
      <VideoModal />
    </>
  );
};
