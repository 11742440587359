/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { Container, Text, RichText, Image } from "@base";
import { Link, Button, Icon } from "@atoms";
import classNames from "classnames";
import gsap from "gsap";

const CbImageWithAccordion = ({
  image,
  caption,
  heading,
  content,
  portrait,
}) => {
  const [active, setActive] = useState(false);
  const contentContainer = useRef();
  const hasCaption = caption?.html.length && caption?.text !== "";

  useEffect(() => {
    if (contentContainer.current) {
      if (active) {
        gsap.to(contentContainer.current, { height: "auto", duration: 0.333 });
      } else {
        gsap.to(contentContainer.current, { height: "3rem", duration: 0.333 });
      }
    }
  }, [active]);

  return (
    <div className="bg-gray-0 py-12">
      <Container size="article">
        <div
          className={classNames(
            "",
            { "mt-10": hasCaption },
            { "my-10": !hasCaption },
            { "px-0 md:px-40": portrait }
          )}
        >
          <Image
            {...image}
            placeholder={false}
            fitHeight
            caption={hasCaption && !portrait ? caption.html : null}
            actualFit={portrait}
          />
        </div>
        {portrait && hasCaption && (
          <Container size="full" className="mb-10">
            <RichText html={caption.html} className="text-gray-2 pt-4" />
          </Container>
        )}

        <Text tag="h3" className={classNames("mb-6", { "mt-6": hasCaption })}>
          {heading}
        </Text>

        <div ref={contentContainer} className="overflow-hidden mb-6">
          <RichText html={content} className="text-gray-2" />
        </div>

        <button
          type="button"
          className="uppercase font-bold tracking-wider flex items-center "
          onClick={() => {
            if (active) {
              setActive(false);
            } else {
              setActive(true);
            }
          }}
        >
          <Icon
            name={active ? "minus" : "plus"}
            className="transition duration-333 ease-in-out w-4 mr-6"
          />

          <span className="mt-1">{active ? "Close" : "Read more"}</span>
        </button>
      </Container>
    </div>
  );
};

CbImageWithAccordion.defaultProps = {};

CbImageWithAccordion.propTypes = {};

export default CbImageWithAccordion;
