import React, { useRef, useEffect, useState, useCallback } from "react";
import propTypes from "prop-types";
import { useWindowSize } from "@designbycosmic/cosmic-react-resize-hook";
import gsap from "gsap";
import { Icon } from "@base";
import tailwindConfig from "@tailwind";
import { isTouchDevice } from "@utils";

const Carousel = React.memo(
  ({
    children,
    showMultiple,
    prevButton: PrevButtonComp,
    nextButton: NextButtonComp,
    maxVisible,
    className: _className,
    buttonPosition,
    centerItems,
    showIndicators,
    gradient,
  }) => {
    // load hammer only if in browser
    let Hammer;
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      Hammer = require("hammerjs");
    }
    const hManager = useRef(null);
    const hDrag = useRef(null);
    const isTouch = useRef(false);

    // set up refs
    const prevButtonRef = useRef();
    const nextButtonRef = useRef();
    const carouselContainer = useRef();
    const carousel = useRef();
    const slideContainer = useRef();

    // set up states
    const { innerWidth: windowSize } = useWindowSize();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideWidth, setSlideWidth] = useState(0);
    const [carouselWidth, setCarouselWidth] = useState(0);
    const [visibleSlides, setVisiblSlides] = useState(1);
    const totalSlides = React.Children.count(children);

    // get screen sizes
    const { screens } = tailwindConfig.theme;

    // determine if the device supports touch

    if (typeof window !== "undefined") {
      isTouch.current = true;
    }

    let options;
    // gsap animation options
    if (isTouch.current) {
      options = { ...options, duration: 0.2, ease: "expo.out" };
    } else {
      options = { duration: 0.33, ease: "power1.out" };
    }

    // * turn children into array
    const items = React.Children.toArray(children);
    const itemIDs = React.Children.toArray(children).map((child, i) => {
      return `child-${i}`;
    });

    // * Carousel Layout Functions

    // calculate # of slides that are visible
    const calculateVisibleSlides = windowWidth => {
      if (showMultiple) {
        const screenNumbers = {};
        Object.keys(screens).map(screen => {
          if (typeof screens[screen] === "string") {
            screenNumbers[screen] = parseInt(
              screens[screen].replace("px", ""),
              10
            );
          }
          return true;
        });
        // configure number of slides based on screen size
        const noSlides = {
          // only use odd numbers (need a center slide)
          sm: 1,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 5,
        };
        // match screen
        const matchedScreen = Object.keys(screenNumbers).find(screen => {
          return windowWidth < screenNumbers[screen];
        });
        // return match
        if (matchedScreen) {
          return noSlides[matchedScreen] <= maxVisible
            ? noSlides[matchedScreen]
            : maxVisible;
        }
        // else return 2
        return maxVisible;
      }
      return 1;
    };

    // set slide width on screen resize
    useEffect(() => {
      const newSlides = calculateVisibleSlides(windowSize);
      if (newSlides !== visibleSlides) {
        setVisiblSlides(newSlides);
      } else if (
        carouselContainer.current.clientWidth / visibleSlides !==
        slideWidth
      ) {
        setCarouselWidth(carouselContainer.current.clientWidth);
        setSlideWidth(carouselContainer.current.clientWidth / visibleSlides);
      }
    }, [windowSize]);

    // after determining number of slides, determine width
    useEffect(() => {
      if (
        carouselContainer.current.clientWidth / visibleSlides !==
        slideWidth
      ) {
        setCarouselWidth(carouselContainer.current.clientWidth);
        setSlideWidth(carouselContainer.current.clientWidth / visibleSlides);
      }
    }, [visibleSlides]);

    // * Carousel UI Functions

    // calculate current carousel position
    const currentPosition = () => {
      return (
        (slideWidth * (totalSlides - visibleSlides)) / 2 +
        slideWidth * -currentSlide
      );
    };

    // handle changing of slide
    const changeSlide = useCallback((dir, slidePos) => {
      if (
        dir === "next" &&
        ((visibleSlides > 1 && slidePos < totalSlides - visibleSlides) ||
          (visibleSlides === 1 && slidePos < totalSlides - 1))
      ) {
        setCurrentSlide(prevState => prevState + 1);
        return;
      }
      if (dir === "prev" && slidePos > 0) {
        setCurrentSlide(prevState => prevState - 1);
        return;
      }
      gsap.to(carousel.current, { x: currentPosition(), ...options });
    }, []);

    // determinie whether or not to hide buttons, then do it
    const handleChangeSlide = () => {
      const btl = gsap.timeline();
      if (
        (visibleSlides > 1 && currentSlide >= totalSlides - visibleSlides) ||
        (visibleSlides === 1 && currentSlide >= totalSlides - 1)
      ) {
        btl
          .set(nextButtonRef.current, { pointerEvents: "none" })
          .to(nextButtonRef.current, {
            duration: 0.25,
            opacity: 0,
            ease: "power1.out",
          })
          .set(nextButtonRef.current, { display: "none" });
      } else {
        btl
          .set(nextButtonRef.current, {
            display: "block",
            pointerEvents: "auto",
          })
          .to(nextButtonRef.current, {
            duration: 0.25,
            opacity: 1,
            ease: "power1.in",
          });
      }
      if (currentSlide <= 0) {
        btl
          .set(prevButtonRef.current, { pointerEvents: "none" })
          .to(prevButtonRef.current, {
            duration: 0.25,
            opacity: 0,
            ease: "power1.out",
          })
          .set(prevButtonRef.current, { display: "none" });
      } else {
        btl
          .set(prevButtonRef.current, {
            display: "block",
            pointerEvents: "auto",
          })
          .to(prevButtonRef.current, {
            duration: 0.25,
            opacity: 1,
            ease: "power1.in",
          });
      }
      return null;
    };

    // initial setup functions
    useEffect(() => {
      if (typeof window !== "undefined" && isTouch.current) {
        if (hManager.current == null) {
          // setup hammerjs for mobile interaction
          hManager.current = new Hammer.Manager(carousel.current);
          // set up pan instance
          hDrag.current = new Hammer.Pan({
            direction: Hammer.DIRECTION_HORIZONTAL,
            threshold: 10,
            pointers: 1,
          });
          // add drag instance to manager
          hManager.current.add(hDrag.current);
        }
      }
    }, []);

    // change carousel position after slide change
    useEffect(() => {
      // should button show or hide?
      handleChangeSlide();
      // calculate carousel position
      gsap.to(carousel.current, { x: currentPosition(), ...options });
      // touch stuff
      if (typeof window !== "undefined" && isTouch.current) {
        hManager.current.off("panleft panright");
        // add new event touch listener
        hManager.current.on("panleft panright", e => {
          const cp = currentPosition();
          // set translate to deltaX value + current position of carousel
          if (e.srcEvent.type !== "pointercancel") {
            gsap.set(carousel.current, {
              x: e.deltaX + cp,
            });
          } else {
            gsap.to(carousel.current, { x: cp, ...options });
          }

          if (e.isFinal) {
            // vert swipe distance is less than horz swip distance is greater
            if (Math.abs(e.deltaY) < Math.abs(e.deltaX)) {
              // next slide if it moved far enough and is not last slide
              if (
                e.deltaX < slideWidth / -4 && // horsz swipe distance is greater than 1/3 card width
                currentSlide < totalSlides - 1 // carousel is not currently on the last slide
              ) {
                changeSlide("next", currentSlide);
                return;
              }
              // prev slide if it moved far enough and is not first slide
              if (
                e.deltaX > slideWidth / 4 && // horz swipe distance is greater than 1/3 card width
                currentSlide > 0 // carousel is not currently on the first slide
              ) {
                changeSlide("prev", currentSlide);
                return;
              }
            }
            // reset position if it didn't move far enough
            gsap.to(carousel.current, { x: cp, ...options });
          }
        });
      }
    }, [currentSlide, slideWidth]);

    const PrevButton = React.memo(() => {
      if (PrevButtonComp) {
        return <PrevButtonComp />;
      }
      return (
        <div className="relative flex items-center justify-center">
          <Icon name="arrow2" fitHeight className="w-10 h-10" />
        </div>
      );
    });

    const NextButton = React.memo(() => {
      if (NextButtonComp) {
        return <NextButtonComp />;
      }
      return (
        <div className="relative flex items-center justify-center">
          <Icon
            name="arrow2"
            fitHeight
            className="w-10 h-10 transform rotate-180"
          />
        </div>
      );
    });

    return (
      <>
        {/* prev button */}
        <div
          className="absolute top-0 bottom-0 left-0 flex items-center pl-3 z-10"
          style={{
            transform: `translateX(${
              buttonPosition.includes("-")
                ? buttonPosition.replace("-", "")
                : `-${buttonPosition}`
            })`,
          }}
        >
          <button
            ref={prevButtonRef}
            type="button"
            className="hidden pr-px rounded-full cursor-pointer text-white"
            onClick={() => changeSlide("prev", currentSlide)}
          >
            <PrevButton />
          </button>
        </div>
        {/* next button */}
        <div
          className="absolute top-0 bottom-0 right-0 flex items-center pr-3 z-10"
          style={{ transform: `translateX(${buttonPosition})` }}
        >
          <button
            ref={nextButtonRef}
            type="button"
            className="hidden pr-px rounded-full cursor-pointer text-white"
            onClick={() => changeSlide("next", currentSlide)}
          >
            <NextButton />
          </button>
        </div>
        <div
          className={`relative ${_className}
          ${
            gradient
              ? "px-4 sm:px-8 md:px-16 lg:px-32 -mx-4 sm:-mx-8 md:-mx-16 lg:-mx-32"
              : "-mx-2 px-2 lg:-mx-4 lg:px-4 xl:-mx-12 xl:px-12"
          }`}
        >
          {/* the actual carousel */}
          <div
            ref={carouselContainer}
            className="card-carousel__container relative z-0 overflow-visible"
          >
            {gradient && (
              <span
                className={`block fade-to-${gradient}-horz absolute left-0 top-0 bottom-0 transform -translate-x-full rotate-180 pointer-events-none z-10`}
                style={{
                  width: ((windowSize || 0) - carouselWidth) / 2,
                }}
              />
            )}
            <div ref={carousel} className="carousel z-0">
              <div ref={slideContainer} className="w-full flex justify-center">
                {items.map((slide, i) => {
                  return (
                    <div
                      key={itemIDs[i]}
                      className={`pointer-events-none w-full flex flex-grow flex-col flex-shrink-0 transition duration-333 px-4 lg:px-8 ${
                        i > currentSlide - 1 && i < currentSlide + visibleSlides
                          ? "opacity-100"
                          : "opacity-40"
                      }`}
                    >
                      <div
                        className={`flex flex-col flex-grow
                        ${centerItems ? "justify-center" : "justify-start"} `}
                      >
                        {slide}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {gradient && (
              <div
                className={`fade-to-${gradient}-horz absolute right-0 top-0 bottom-0 transform translate-x-full pointer-events-none z-10`}
                style={{
                  width: ((windowSize || 0) - carouselWidth) / 2,
                }}
              />
            )}
          </div>
          {showIndicators && items.length > 1 && (
            <div className="flex items-center justify-center mt-6">
              {items.map((slide, i) => {
                return (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button
                    type="button"
                    className={`w-2 h-2 bg-white mx-2 ${
                      currentSlide === i ? "opacity-100" : "opacity-50"
                    }`}
                    onClick={() => {
                      setCurrentSlide(i);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
);

Carousel.defaultProps = {
  showMultiple: false,
  prevButton: null,
  nextButton: null,
  maxVisible: 5,
  showIndicators: false,
  buttonPosition: "-50%",
  className: "",
  centerItems: false,
};

Carousel.propTypes = {
  showMultiple: propTypes.bool,
  prevButton: propTypes.func,
  centerItems: propTypes.bool,
  showIndicators: propTypes.bool,
  // children: ,
  nextButton: propTypes.func,
  maxVisible: propTypes.number,
  buttonPosition: propTypes.string,
  className: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Carousel;
