import React from "react";
import PropTypes from "prop-types";
import { Button as CosmicButton } from "@designbycosmic/cosmic-react-common-components";

const ButtonUnStyled = ({ onClick, className, ...rest }) => {
  return (
    <CosmicButton
      {...rest}
      onClick={onClick}
      className={`outline-none focus:outline-none ${className}`}
    />
  );
};

ButtonUnStyled.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

ButtonUnStyled.defaultProps = {
  onClick: undefined,
  className: "",
};

export default ButtonUnStyled;
