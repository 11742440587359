import React from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, imageProps, Icon } from "@base";
import { Link } from "@atoms";
import { AnimateIn } from "@ui";

const ArticleCardSmall = ({
  image,
  logo,
  heading,
  shortTitle,
  summary,
  excerpt,
  url,
  target,
  className,
  style,
  type,
}) => {
  return (
    <AnimateIn
      preset="fadeUpFast"
      className={`${className} h-full relative`}
      style={style}
    >
      <Link
        to={url}
        target={target}
        className="flex group transform hover:-translate-y-1 transition duration-166 h-full flex-col flex-growp-3 bg-white shadow-xl hover:shadow-2xl rounded-b-lg"
      >
        <Image {...image} aspectratio="8:5" />

        <div className="p-6 pb-6 font-light leading-snug">
          <div className="text-blue uppercase font-bold text-xs mb-1">
            {type === "news" && "news"}
            {type === "pr" && "Press Release"}
          </div>
          {
            <h4 className="text-xl leading-1.2 sm:text-lg">
              {shortTitle || heading}
            </h4>
          }
          {summary && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: excerpt || summary?.html,
              }}
              className="text-xs leading-1.2 mt-1 sm:text-sm"
            />
          )}
        </div>
        {/* <div className="mt-4">
          <Button bgColor="blue" text="Learn More" className="mr-2" />
        </div> */}
        {/* <Link to={url} /> */}
        {/* {type === "pr" && (
          <Icon
            name="jobyIcon"
            className="h-16 py-3 px-6 text-blue mt-auto mb-3"
            fitHeight
          />
        )}
        {type === "news" && (
          <div className="px-6 h-16 mt-auto mb-3">
            {logo && <Image {...logo} fitHeight />}
          </div>
        )} */}
      </Link>
    </AnimateIn>
  );
};

export const articleCardProps = {
  image: imageProps.isRequired,
  heading: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export const defaultArticleCardProps = {
  url: null,
  target: null,
  className: "",
  style: {},
};

ArticleCardSmall.propTypes = articleCardProps;

ArticleCardSmall.defaultProps = defaultArticleCardProps;

export default ArticleCardSmall;
