import { registerMiddleware } from "@designbycosmic/cosmic-react-state-management";
import { cookies } from "@utils";

const { setCookie } = cookies;

const reducer = (state, action) => {
  switch (action.type) {
    case "announcement.hide":
      return {
        ...state,
        showing: false,
        uid: null,
      };
    case "announcement.show":
      return {
        ...state,
        showing: true,
        uid: action.uid,
      };

    default:
      return state;
  }
};

registerMiddleware({
  actionType: "announcement.hide",
  func: ({ uid }) => {
    // When calling `hideAnnouncement` we need to pass the announcementId
    // in the action param

    setCookie("announcement", uid, 7);
  },
});

const initialState = {
  showing: false,
  uid: null,
};

export default {
  reducer,
  initialState,
};
