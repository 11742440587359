import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import parse, { domToReact } from "html-react-parser";
import { Link } from "@atoms";
import { List, ListItem } from "@molecules";
import Text from "./Text";

const RichText = ({
  html,
  size,
  backgroundIsDark,
  addBeforeFirst,
  className,
  style,
  article,
  quote,
}) => {
  const skip = ["img", "br"];

  const options = {
    replace: useCallback(({ name, attribs, next, prev, children }) => {
      // This is a hacky method to munge text in front of the first
      // instance of a particular tag. Used to add date and location
      // in front of press release copy
      if (!prev && addBeforeFirst && addBeforeFirst.searchTag === name) {
        const Tag = addBeforeFirst.tag;
        return (
          <Text tag={name} preserveWidows>
            <Tag>{addBeforeFirst.text}</Tag> {domToReact(children, options)}
          </Text>
        );
      }

      // Apply big heading styles
      if (name === "h2") {
        return (
          <Text
            tag={name}
            preserveWidows
            className={classNames({
              "font-sans": true,
              "mt-6": !!prev,
              "mb-3": !!next && size === "base",
              "mb-6": !!next && size === "lg",
            })}
            options={{
              size: "text-xl",
            }}
          >
            {domToReact(children, options)}
          </Text>
        );
      }

      // Apply theme color to main headings
      if (name === "h3" || name === "h4") {
        return (
          <Text
            tag={name}
            preserveWidows
            use={article ? "span" : name}
            className={classNames({
              "font-sans": !article,
              "transition duration-333 mt-6 mb-4 text-lg leading-1.2 text-blue font-bold uppercase tracking-wide":
                article,
              "mt-6": !!prev,
              "mb-4": !!next && size === "base",
              "mb-6": !!next && size === "lg",
            })}
            options={{
              size: "text-lg",
            }}
          >
            {domToReact(children, options)}
          </Text>
        );
      }

      // Use styled inline links
      if (name === "a") {
        return (
          <Link
            to={attribs.href}
            target={attribs.target}
            className={classNames({
              "transition duration-166 ease-in-out": true,
              "text-blue hover:text-blue-hover": !backgroundIsDark,
              "text-blue hover:text-blue": backgroundIsDark,
            })}
          >
            {domToReact(children, options)}
          </Link>
        );
      }

      // Use styled lists
      if (name === "ul" || name === "ol") {
        return <List tag={name}>{domToReact(children, options)}</List>;
      }

      // Use styled lists
      if (name === "li") {
        return (
          <ListItem article={article}>{domToReact(children, options)}</ListItem>
        );
      }

      if (name && quote) {
        return (
          <Text
            tag={name}
            options={{
              size: "text-2xl",
              font: "font-sans",
              lineHeight: "leading-1.2",
            }}
            className="tracking-2 font-sans"
            preserveWidows
          >
            {domToReact(children, options)}
          </Text>
        );
      }

      // render everything else using default styles
      if (name && !skip.includes(name)) {
        return (
          <Text
            tag={name}
            className={classNames({
              "mt-6": !!prev,
              "mb-6": !!next,
            })}
            options={{ size: `text-${size}` }}
            preserveWidows
          >
            {domToReact(children, options)}
          </Text>
        );
      }

      return null;
    }),
  };

  if (typeof html !== "string") {
    return null;
  }

  const parsedHtml = parse(html, options);

  return (
    <div
      className={`transition rich-text duration-333 ease-in-out ${className}`}
      style={style}
    >
      {parsedHtml}
    </div>
  );
};

RichText.propTypes = {
  html: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["base", "lg", "sm"]),
  addBeforeFirst: PropTypes.shape({
    searchTag: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  backgroundIsDark: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

RichText.defaultProps = {
  size: "base",
  addBeforeFirst: null,
  backgroundIsDark: false,
  className: "",
  style: {},
};

export default RichText;
