const reducer = (state, action) => {
  switch (action.type) {
    case "navigation.navigatedFrom": {
      return {
        ...state,
        previousPath: action.path,
      };
    }

    default:
      return state;
  }
};

const initialState = {
  previousPath: null,
};

export default {
  reducer,
  initialState,
};
