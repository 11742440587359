import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel, resolveImage, mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    prismicFooter {
      data {
        menu_heading
        menu {
          title
          link {
            url
          }
        }
        contact {
          heading
          phone
          email
        }
        policy_pages {
          title
          link {
            url
          }
        }
        background_image {
          url
          dimensions {
            width
            height
          }
        }
        copyright_text
        social_links {
          platform
          social_link {
            url
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const {
    menuHeading,
    menu,
    contact,
    social,
    policyPages,
    backgroundImage,
    socialLinks,
    ...rest
  } = keysToCamel(response.prismicFooter.data);

  return {
    ...rest,
    menu: menu.map((item, idx) => ({
      uid: idx,
      title: item.title,
      url: item.link.url,
    })),
    contact: contact[0],
    policyPages: policyPages.map((item, idx) => ({
      uid: idx,
      title: item.title,
      url: item.link.url,
    })),
    backgroundImage: resolveImage(backgroundImage),
    social: socialLinks.map(link => {
      return { platform: link.platform, url: link.socialLink.url };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
