import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Section is used to separate content vertically, often
// with a different background color

const Section = ({
  hasPaddingTop,
  hasPaddingBottom,
  hasMarginTop,
  hasMarginBottom,
  size,
  allowOverflow,
  className: _className,
  children,
  style,
}) => {
  // if we passed a custom `className` string then use that,
  // else build it based on other props
  const className = classNames(
    {
      relative: true,
      "overflow-hidden": !allowOverflow,
      "pt-10 sm:pt-12 md:pt-24": hasPaddingTop && size === "lg",
      "pb-10 sm:pb-12 md:pb-24": hasPaddingBottom && size === "lg",
      "pt-16 sm:pt-24 md:pt-32": hasPaddingTop && size === "xl",
      "pb-16 sm:pb-24 md:pb-32": hasPaddingBottom && size === "xl",
      "pt-6 sm:pt-12": hasPaddingTop && size === "sm",
      "pb-6 sm:pb-12": hasPaddingBottom && size === "sm",

      "mt-10 sm:mt-12 md:mt-24": hasMarginTop && size === "lg",
      "mb-10 sm:mb-12 md:mb-24": hasMarginBottom && size === "lg",
      "mt-16 sm:mt-24 md:mt-32": hasMarginTop && size === "xl",
      "mb-16 sm:mb-24 md:mb-32": hasMarginBottom && size === "xl",
      "mt-6 sm:mt-12": hasMarginTop && size === "sm",
      "mb-6 sm:mb-12": hasMarginBottom && size === "sm",
    },
    _className
  );

  return (
    <section className={`Section ${className}`} style={style}>
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  hasPaddingTop: PropTypes.bool,
  hasPaddingBottom: PropTypes.bool,
  hasMarginTop: PropTypes.bool,
  hasMarginBottom: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  allowOverflow: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Section.defaultProps = {
  hasPaddingTop: true,
  hasPaddingBottom: true,
  hasMarginTop: false,
  hasMarginBottom: false,
  size: "lg",
  allowOverflow: false,
  className: "",
  style: {},
};

export default Section;
