/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Video, Link, Button } from "@atoms";
import { RichText, Text, Container, Icon, Image } from "@base";
import classNames from "classnames";
import { useAppState } from "@state/appState";

const SliceFullBleedVideo = ({
  videoUrl,
  heading,
  copy,
  preserveAspectRatio,
  showControls,
  autoplay,
  textY,
  textX,
  darkText,
  buttons,
  first,
  placeholder,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();
  return (
    <div
      className={classNames("relative flex flex-wrap w-full", {
        "min-h-1/2-screen sm:min-h-2/3-screen md:min-h-screen":
          !preserveAspectRatio,
      })}
    >
      {!!placeholder?.url?.length && (
        <div className="absolute inset-0">
          <Image {...placeholder} fill />
        </div>
      )}
      <div
        className={classNames("z-10 w-full", {
          "absolute inset-0": !preserveAspectRatio,
          relative: preserveAspectRatio,
        })}
      >
        <Video
          url={videoUrl}
          preserveAspectRatio={preserveAspectRatio}
          showControls={showControls}
          autoplay={autoplay}
          forcePlay={first}
        />
      </div>
      <div
        className={classNames({
          "relative sm:absolute inset-0": preserveAspectRatio,
          relative: !preserveAspectRatio,
          "bg-gray sm:bg-transparent": preserveAspectRatio && !darkText,
          "bg-white sm:bg-transparent": preserveAspectRatio && darkText,
          "text-white": !darkText,
          "text-gray": darkText,
          "z-20  flex-grow flex": true,
        })}
        style={darkText ? null : { textShadow: "0 0 30px black" }}
      >
        <Container
          className={classNames({
            "justify-start": textY === "top",
            "justify-center": textY === "middle",
            "justify-end": textY === "bottom",
            "items-center": textX === "center",
            "items-start": textX === "left",
            "items-end": textX === "right",
            "relative flex flex-col h-full w-full flex-grow": true,
          })}
        >
          <div className="sm:w-2/3 md:w-3/5 lg:w-1/2 py-8 sm:py-12">
            <div className="flex flex-col">
              {heading && (
                <Text
                  tag={first ? "h1" : "h2"}
                  className={classNames({
                    "text-center": textX === "center",
                    "text-gray-dark": darkText,
                  })}
                >
                  {heading}
                </Text>
              )}
              {copy && (
                <RichText
                  html={copy}
                  size="lg"
                  className={classNames({
                    "text-center": textX === "center",
                    "mt-6": heading,
                  })}
                />
              )}
              <div className="flex items-center justify-center">
                {!!buttons?.length &&
                  buttons.map(button => {
                    if (
                      button.url?.match("http(s)?://(www.)?youtube|youtu.be")
                    ) {
                      return (
                        <>
                          <button
                            type="button"
                            className="items-center flex group"
                            onClick={() => {
                              dispatch({
                                type: "playVideo",
                                url: button.url,
                              });
                            }}
                          >
                            <Icon
                              name="playButton"
                              className="h-6 w-6 mr-2 text-blue group-hover:text-white transition duration-333"
                            />
                            <span
                              style={
                                darkText
                                  ? null
                                  : { textShadow: "0 0 15px rgba(0,0,0,.5)" }
                              }
                            >
                              {button.text}
                            </span>
                          </button>
                        </>
                      );
                    }
                    return (
                      <Link to={button.url} className="inline-flex m-3">
                        <Button bgColor="orange" text={button.text} />
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

SliceFullBleedVideo.defaultProps = {
  play: false,
};

SliceFullBleedVideo.propTypes = {};

export default SliceFullBleedVideo;
