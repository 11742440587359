import React, { useRef, useEffect, useState } from "react";
import propTypes from "prop-types";
import { useAppState } from "@state/appState";
import gsap from "gsap";
import { Icon, VideoPlayer } from "@atoms";

const VideoModal = () => {
  const [{ videoModal }, dispatch] = useAppState();
  const { url: videoModalUrl } = videoModal;
  const videoModalRef = useRef();

  const [pause, setPause] = useState(false);

  useEffect(() => {
    if (videoModalUrl) {
      gsap.to(videoModalRef.current, {
        pointerEvents: "auto",
        opacity: 1,
      });
    }
  }, [videoModalUrl]);
  return (
    <div
      ref={videoModalRef}
      className="fixed flex items-center justify-center inset-0 opacity-0 pointer-events-none"
      style={{
        zIndex: 999,
        background: "rgba(0,0,0,.9)",
      }}
    >
      <button
        type="button"
        className="block absolute inset-0 h-full w-full"
        onClick={() => {
          gsap.to(videoModalRef.current, {
            pointerEvents: "none",
            opacity: 0,
            onComplete: () => {
              setTimeout(() => {
                dispatch({
                  type: "closeVideo",
                });
              });
            },
          });
        }}
      >
        <span className="absolute top-0 right-0 p-3 h-20 w-20 block">
          <svg
            className="block w-full h-full"
            viewBox="0 0 64 64"
            stroke="white"
            strokeLinecap="round"
          >
            <path d="M42 22L22 42M42 42L22 22.0001" />
          </svg>
        </span>
      </button>
      <div className="w-full w-full sm:w-3/4 relative z-10">
        {videoModalUrl && (
          <VideoPlayer autoplay url={videoModalUrl} pause={pause} />
        )}
      </div>
    </div>
  );
};

VideoModal.defaultProps = {};

VideoModal.propTypes = {};

export default VideoModal;
