const reducer = (state, action) => {
  switch (action.type) {
    case "layout.setPreviewMode":
      return {
        ...state,
        previewMode: action.previewMode,
      };
    case "layout.toggleMobileNav":
      return {
        ...state,
        showingMobileNav: !state.showingMobileNav,
      };
    case "layout.setTransition":
      return {
        ...state,
        transitionTo: action.transitionTo,
      };
    case "layout.setHydrated":
      return {
        ...state,
        hydrated: true,
      };
    case "layout.setIsTouchDevice":
      return {
        ...state,
        isTouchDevice: action.isTouchDevice,
      };
    case "layout.setBreakpoints":
      return {
        ...state,
        breakpoints: action.breakpoints,
      };
    case "layout.loadGtm":
      return {
        ...state,
        gtmLoaded: true,
      };

    default:
      return state;
  }
};

const initialState = {
  previewMode: false,
  showingMobileNav: false,
  transitionTo: false,
  hydrated: false,
  isTouchDevice: false,
  gtmLoaded: false,
  breakpoints: {
    sm: null,
    md: null,
    lg: null,
    xl: null,
    current: null,
  },
};

export default {
  reducer,
  initialState,
};
