export const formatPhone = n => {
  if (n.length === 7) {
    return `${n.substr(0, 3)}-${n.substr(3, 4)}`;
  }
  if (n.length === 10) {
    return `+1-${n.substr(0, 3)}-${n.substr(3, 3)}-${n.substr(6, 4)}`;
  }
  return n;
};

export default formatPhone;
