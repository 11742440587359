import { useStaticQuery, graphql } from "gatsby";
import {
  keysToCamel,
  resolveSlices,
  resolveObjectWithImage,
  mergePrismicPreview,
  resolveImage,
} from "@dataResolvers";

const query = graphql`
  query HomePageQuery {
    prismicHomePage {
      prismicId
      id
      data {
        hero {
          heading
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
          video_embed_id
        }
        # prefooter
        heading
        email_cta
        email_button
        featured_news {
          news_item {
            document {
              ... on PrismicPressReleases {
                uid
                url
                type
                data {
                  image {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  meta {
                    title
                    description
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                  heading
                  date(formatString: "MMM DD, YYYY")
                  datetime
                  short_title
                  excerpt
                  summary {
                    html
                  }
                }
              }
              ... on PrismicArticles {
                uid
                type
                data {
                  publisher_logo {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  image {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  heading
                  date(formatString: "MMM DD, YYYY")
                  short_title
                  summary {
                    html
                  }
                  external_link {
                    url
                    target
                  }
                }
              }
            }
          }
        }
        # meta
        meta {
          title
          description
          image {
            url
            dimensions {
              width
              height
            }
          }
        }
        # main content
        body {
          ... on PrismicHomePageBodyFullBleedImage {
            id
            primary {
              heading
              descriptor {
                html
              }
              image {
                dimensions {
                  height
                  width
                }
                url
              }
              image_color
              size
              text_horizontal_position
              text_vertical_position
              text_color
            }
            items {
              color
              button_label
              button_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomePageBodyImageCopyIcons {
            slice_type
            id
            primary {
              heading
              image_position
              copy {
                html
              }
              image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              caption
              icon {
                document {
                  ... on PrismicIcon {
                    id
                    data {
                      svg_code {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageBodyFullBleedVideo {
            id
            primary {
              autoplay
              heading
              descriptor {
                html
              }
              preserve_aspect_ratio
              show_controls
              size
              text_horizontal_position
              text_vertical_position
              vimeo_url
              text_color
              placeholder_image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              button_label
              button_link {
                url
              }
              color
            }
            slice_type
          }
          ... on PrismicHomePageBodyTimelineItem {
            id
          }
          ... on PrismicHomePageBodyImageCopyQuote {
            id
            primary {
              image_position
              citation {
                html
              }
              copy {
                html
              }
              heading
              quote {
                html
              }
              image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicHomePageBodyLogoGrid {
            id
          }
        }
      }
    }
  }
`;

const resolveArticle = article => {
  const { uid, data } = article;
  const { externalLink, image, publisherLogo, ...rest } = keysToCamel(data);
  return {
    uid,
    ...rest,
    ...externalLink,
    image: resolveImage(image),
    target: "_blank",
    assets: [],
    type: "news",
    logo: resolveImage(publisherLogo),
  };
};

const resolvePressRelease = article => {
  const { uid, url, data } = article;
  const { meta, image, summary, shortTitle, ...rest } = keysToCamel(data);

  return {
    ...rest,
    uid,
    url,
    image: resolveImage(meta?.[0]?.image) || resolveImage(image),
    shortTitle,
    summary,
    assets: [],
    type: "pr",
  };
};

const dataResolver = response => {
  const {
    uid,
    hero,
    meta,
    body,
    heading,
    emailHeading,
    emailCta,
    featuredNews,
  } = keysToCamel(response.prismicHomePage.data);

  return {
    uid,
    hero: hero[0],
    slices: resolveSlices(body),
    prefooter: {
      heading,
      emailHeading,
      emailCta,
      articles: featuredNews.map(_news => {
        const news = _news.newsItem.document;
        if (news?.type === "articles") {
          return resolveArticle(news);
        }
        if (news?.type === "press_releases") {
          return resolvePressRelease(news);
        }
        return null;
      }),
    },
    meta: resolveObjectWithImage(meta[0]),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
