/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import { useWindowSize } from "@designbycosmic/cosmic-react-resize-hook";
import gsap from "gsap";
import { useScrollPosition } from "@hooks";

// build query string function to turn object in url query params
const queryString = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join("&");

const getParams = url => {
  const urlParams = url.split("?")[1];
  if (urlParams) {
    const paramSeries = urlParams.split("&");
    const paramsObj = {};
    paramSeries.forEach(param => {
      const paramSplit = param.split("=");
      // eslint-disable-next-line prefer-destructuring
      paramsObj[paramSplit[0]] = paramSplit[1];
    });
    return paramsObj;
  }
  return false;
};

const Image = ({
  url: _url,
  caption,
  captionClasses,
  fit,
  alt,
  aspectratio,
  position,
  delay,
  placeholder,
  lazyLoad,
  fill,
  params,
  dimensions,
  parallax,
  transparent,
  // focalPoint,
  round,
  bw,
  fitHeight,
  actualFit,
}) => {
  if (_url && dimensions) {
    const url = _url.split("?")[0];
    const { height: _height, width: _width } = dimensions;
    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);
    const [clientWidth, setClientWidth] = useState(0);
    const { innerWidth: windowSize, innerHeight: windowHeight } =
      useWindowSize();
    const imgContainer = useRef();
    const imgRef = useRef();
    const imgPlaceholder = useRef();

    // get pixel device ratio, set to 1 if window or pdr is undefined
    const pr = typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1;

    // calculate aspect ratio
    let arm;
    if (aspectratio != null) {
      const arSplit = aspectratio.includes("/")
        ? aspectratio.split("/")
        : aspectratio.split(":");
      arm = parseInt(arSplit[1], 10) / parseInt(arSplit[0], 10);
    }

    const existingParams = getParams(_url);
    const rect =
      existingParams && existingParams.rect ? existingParams.rect : null;

    // configure default params
    const defaultParams = {
      auto: "compress,format",
      fit: actualFit ? "" : "crop",
      crop: actualFit ? "" : "faces",
      rect,
      q: pr > 1 ? 10 : 80,
      "blend-color": bw ? "000000" : null,
      "blend-mode": bw ? "color" : null,
      w: width,
      h: height,
      // w:
      //   renderWidth *
      //   (typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1), // from state
      // h:
      //   renderHeight *
      //   (typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1), //  from state
    };

    // merge with params from prop
    const ixParams = {
      ...defaultParams,
      ...params,
    };

    // if focal point is set, overwrite crop settings
    // if (focalPoint) {
    //   ixParams = {
    //     ...ixParams,
    //     fit: "crop",
    //     crop: "focalpoint",
    //     "fp-x": focalPoint[0],
    //     "fp-y": focalPoint[1],
    //   };
    // }

    // load placeholder image – helps browser determine image size before it loads
    const lqipSrc = `${url}?${queryString({
      ...defaultParams,
      w: width / 100,
      h: ((arm ? width * arm : height) / 100) * (parallax ? 1.2 : 1),
      q: 0,
      colorquant: 32,
      blur: 20,
    })}`;

    const getHeight = w => {
      if (arm) {
        return w * arm;
      }
      if (fill) {
        return imgContainer.current.clientHeight;
      }
      return w * (_height / _width);
    };

    // change the height and width on page resize
    useEffect(() => {
      const w = imgContainer.current.clientWidth;
      setClientWidth(w);
      setWidth(
        Math.ceil((imgContainer.current.clientWidth + 1) / 100).toFixed(0) * 100
      );
      setHeight(getHeight(w));
    }, [windowSize, loaded]);

    // Parallax effect

    const {
      scrollY,
      // scrollingUp
    } =
      typeof window !== "undefined"
        ? useScrollPosition()
        : { scrollY: 0, scrollingUp: false };

    useEffect(() => {
      const p = () => {
        if (
          imgContainer.current.getBoundingClientRect().y < windowHeight &&
          imgContainer.current.getBoundingClientRect().y >
            -imgContainer.current.clientHeight
        ) {
          const totalHeight = windowHeight + imgContainer.current.clientHeight;
          const percent =
            (imgContainer.current.getBoundingClientRect().y +
              imgContainer.current.clientHeight) /
            totalHeight;
          return percent;
        }
        return false;
      };

      if (parallax) {
        const _p = p();
        if (_p && _p > 0 && _p < 1) {
          if (imgRef.current) {
            gsap.set(imgRef.current, {
              // yPercent: (_p - 0.5) * 20,
              objectPosition: `50% ${_p * 100}%`,
              // scale: 1.2,
            });
          }
          if (imgPlaceholder.current) {
            gsap.set(imgPlaceholder.current, {
              // yPercent: (c - 0.5) * 20,
              objectPosition: `50% ${_p * 100}%`,
              // scale: 1.2,
            });
          }
        }
      }
    }, [scrollY]);

    useEffect(() => {
      if (!lazyLoad) {
        setVisible(true);
      }
    }, []);

    // fade in the image and fade out the placeholder onLoad
    useEffect(() => {
      if (loaded && visible) {
        setTimeout(() => {
          gsap.fromTo(
            imgRef.current,
            {
              opacity: 0,
            },
            {
              display: "block",
              opacity: 1,
              duration: 0.3,
              ease: "power1.in",
              clearProps: "transform",
              onComplete: () => {
                if (imgRef.current) {
                  imgRef.current.style.transform = null;
                }
              },
            }
          );
          gsap.fromTo(
            imgPlaceholder.current,
            { opacity: 1 },
            {
              opacity: 0,
              duration: 0.3,
              ease: "power1.in",
              delay: delay / 1000,
            }
          );
        }, 50);
      }
    }, [loaded, visible]);

    // get the final url
    const src = `${url}?${queryString({
      ...ixParams,
      w: width,
      h: (arm ? width * arm : height) * (parallax ? 1.2 : 1),
      dpr: pr > 1.5 ? 1.5 : pr,
    })}`;

    // return image
    return (
      <InView
        observerOptions={{
          threshold: 0.01,
        }}
        className={`w-full ${arm ? "h-auto" : "h-full"}`}
        onEnter={() => {
          if (lazyLoad) {
            setVisible(true);
          }
        }}
        unobserveAfterEntry
      >
        <figure
          ref={imgContainer}
          className={`${fitHeight ? "w-auto" : "w-full"} ${
            arm ? "h-auto" : "h-full"
          } relative
          ${caption ? "" : "overflow-hidden"}
          ${round ? "rounded-full overflow-hidden" : ""}`}
          style={
            !fill && !fitHeight && clientWidth
              ? { height: getHeight(clientWidth) || 0 }
              : null
          }
        >
          {/* the actual image */}
          <img
            ref={imgRef}
            className={`z-10 inset-0 opacity-0 hidden transform
              object-${fill ? "cover" : fit} 
              object-${position} 
              ${fill ? "absolute" : "relative"}
              ${arm ? "h-auto" : "h-full"} w-full`}
            src={visible ? src : null}
            alt={alt || "image"}
            onLoad={() => {
              setLoaded(true);
            }}
          />
          {/* placeholder before image load-in */}
          {placeholder && (
            <div
              ref={imgPlaceholder}
              className={`flex items-center justify-center z-0   
            absolute inset-0 overflow-hidden
            object-${fill ? "cover" : fit} 
            object-${position} 
            ${arm ? "h-auto" : "h-full"} 
            w-full transition`}
            >
              <img
                src={lqipSrc}
                alt={alt || "placeholder"}
                style={{ filter: "blur(20px)" }}
                className={`z-10 inset-0 opacity-50 transition duration-700 ease transform
              object-${fill ? "cover" : fit} 
              object-${position} 
              ${fill ? "absolute" : ""}
              ${arm ? "h-auto" : "h-full"} w-full transition`}
              />
            </div>
          )}
          {/* no js fallback */}
          <noscript>
            <img
              className={`absolute inset-0 blur-in object-${fit} h-full w-full transition`}
              src={src}
              alt={alt || "image"}
            />
          </noscript>
          {/* load in a caption below just if provided */}
          {caption && (
            <figcaption
              className={`pt-4 leading-1.5 ${captionClasses}`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </figure>
      </InView>
    );
  }
  return null;
};

Image.defaultProps = {
  url: null,
  caption: null,
  fit: "contain",
  alt: null,
  aspectratio: null,
  position: "center",
  fill: false,
  delay: 500,
  params: null,
  placeholder: true,
  parallax: false,
  lazyLoad: false,
  captionClasses: "text-gray-2",
  actualFit: false,
};

Image.propTypes = {
  url: propTypes.string,
  caption: propTypes.string,
  fit: propTypes.string,
  placeholder: propTypes.bool,
  alt: propTypes.string,
  aspectratio: propTypes.string,
  position: propTypes.string,
  fill: propTypes.bool,
  delay: propTypes.number,
  params: propTypes.objectOf(
    propTypes.oneOfType([propTypes.string, propTypes.number])
  ),
  lazyLoad: propTypes.bool,
  parallax: propTypes.bool,
  // focalPoint: propTypes.oneOfType([propTypes.bool, propTypes.array]).isRequired,
  captionClasses: propTypes.string,
  actualFit: propTypes.bool,
};

export default Image;
