import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useMetaData } from "@staticQueries";
// import { imageProps } from "@base";

const SEO = ({ title: _title, description: _description, image: _image }) => {
  const { defaultTitle, defaultDescription, defaultImage, author } =
    useMetaData();

  // use passed metadata and fallback to site defaults
  const title = `${_title || defaultTitle} | ${author}`;
  const description = _description || defaultDescription;
  const image = _image || defaultImage;

  const { url } = image;
  const imageSrc = `${url}&w=1200&h=630`;

  return (
    <Helmet
      title={title}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1 maximum-scale=1",
        },
        {
          name: "description",
          content: description,
        },
        {
          property: "og:image",
          content: imageSrc,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: description,
        },
      ]}
    />
  );
};

export const seoProps = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.string),
};

SEO.propTypes = seoProps;

SEO.defaultProps = {
  title: undefined,
  description: undefined,
  image: undefined,
};

export default SEO;
