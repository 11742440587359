/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { VideoPlayer } from "@atoms";
import { RichText, Container } from "@base";

const CbVideo = ({ url, caption }) => {
  return (
    <Container size="article">
      <div className="my-6">
        <VideoPlayer url={url} />
        {caption?.html.length > 0 && (
          <div className="text-gray-2 font-mono pt-4 leading-1.5">
            <RichText html={caption.html} />
          </div>
        )}
      </div>
    </Container>
  );
};

CbVideo.defaultProps = {};

CbVideo.propTypes = {};

export default CbVideo;
