import React from "react";
import PropTypes from "prop-types";
import { Button as CosmicButton } from "@designbycosmic/cosmic-react-common-components";
import { Text, Icon } from "@base";

const ButtonBase = ({ text, iconName, className, style, ...rest }) => {
  return (
    <CosmicButton
      {...rest}
      className={`outline-none focus:outline-none ${className}`}
      style={style}
    >
      {text && (
        <Text
          tag="span"
          className="transition duration-333 ease-in-out leading-1"
          // style={{ transform: "translateY(1px)" }} // Font hangs a little high
        >
          {text}
        </Text>
      )}

      {iconName && (
        <Icon
          name={iconName}
          className="transition duration-333 ease-in-out ml-2 w-5 h-5"
        />
      )}
    </CosmicButton>
  );
};

ButtonBase.propTypes = {
  text: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

ButtonBase.defaultProps = {
  text: null,
  iconName: null,
  className: "",
  style: {},
};

export default ButtonBase;
