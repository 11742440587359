import React from "react";
import { Text, RichText, Image } from "@base";
import { Button, Link } from "@atoms";
import { AnimateIn, LinkWrap } from "@ui";
import { useAppState } from "@state/appState";
import { articleCardProps, defaultArticleCardProps } from "./ArticleCard";

const ArticleCardFeatured = ({
  image,
  heading,
  summary,
  url,
  target,
  assets,
  className,
  style,
}) => {
  const [{ layout }] = useAppState();

  return (
    <AnimateIn preset="fadeUpFast" className={className} style={style}>
      <LinkWrap
        useLink={!!url}
        to={url}
        target={target}
        className="flex flex-col-reverse sm:flex-row"
      >
        <div className="flex-1 bg-white p-6">
          <Text tag="h3" className="text-black">
            {heading}
          </Text>
          <RichText html={summary.html} size="sm" className="text-black mt-2" />

          <div className="flex mt-4">
            {/* If assets render those */}
            {assets.map(item => (
              <Link
                key={item.uid}
                to={item.ctaLink.url}
                target={item.ctaLink.target}
                className="mr-2"
              >
                <Button bgColor="blue" text={item.ctaText} />
              </Link>
            ))}

            {url && <Button bgColor="blue" text="Learn more" />}
          </div>
        </div>

        <div className="sm:w-1/3 border-2 border-white">
          <div className="relative w-full h-full">
            <Image
              {...image}
              lazyLoad={false}
              ratio={layout.breakpoints.sm ? undefined : 4 / 3}
            />
          </div>
        </div>
      </LinkWrap>
    </AnimateIn>
  );
};

ArticleCardFeatured.propTypes = articleCardProps;

ArticleCardFeatured.defaultProps = defaultArticleCardProps;

export default ArticleCardFeatured;
