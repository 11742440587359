import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Power4, gsap } from "gsap";
import { useAppState } from "@state/appState";
import { useAnnouncementData } from "@staticQueries";
import { Container, Text, RichText } from "@base";
import { Button, Link } from "@atoms";
import { cookies } from "@utils";

const { hasCookie } = cookies;

const AnnouncementBanner = () => {
  const [{ announcement }, dispatch] = useAppState();
  const banner = useRef();
  const { uid, copy, ctaText, ctaLink, date, isShowing } =
    useAnnouncementData();

  // Set showingAnnouncement to true in app state if following checks pass
  // - no cookie exists for this uid
  // - announcement `isShowing` field is set to true in the CMS
  useEffect(() => {
    if (
      // !hasCookie("announcement", uid) &&
      isShowing
    ) {
      dispatch({
        type: "announcement.show",
        uid,
      });
    }
  }, [isShowing]);

  const show = () => {
    // gsap.to(banner.current, {
    //   duration: 0.5,
    //   opacity: 1,
    //   height: banner.current.scrollHeight,
    //   ease: Power4.easeOut,
    //   onComplete: () => {
    //     if (banner.current) {
    //       banner.current.style.height = "auto";
    //     }
    //   },
    // });
  };

  const hide = () => {
    gsap.to(banner.current, {
      duration: 0.5,
      opacity: 0,
      height: 0,
      ease: Power4.easeOut,
    });
  };

  // Toggle the show/hide animation based on state
  useEffect(() => {
    if (!banner.current) {
      return;
    }
    if (announcement.showing) {
      show();
    } else {
      hide();
    }
  }, [announcement.showing]);

  // // Hide announcement if navigation occurred
  // useEffect(() => {
  //   if (!banner.current) {
  //     return;
  //   }

  //   if (navigation.previousPath) {
  //     hide();
  //   }
  // }, [navigation.previousPath]);

  if (!isShowing) {
    return null;
  }

  return (
    <Link
      ref={banner}
      to={ctaLink.url}
      onClick={() =>
        dispatch({
          type: "announcement.hide",
          uid: announcement.uid,
        })
      }
      className={classNames({
        "overflow-hidden bg-gray-dark text-gray-light z-60": true,
        "pointer-events-auto": announcement.showing,
        "pointer-events-none": !announcement.showing,
      })}
      // style={{ height: 0, opacity: 0 }}
    >
      <Container className="py-3 flex flex-col md:flex-row items-center">
        {date && (
          <Text tag="h4" className="md:mr-auto">
            {date}:
          </Text>
        )}

        <RichText
          html={copy.html}
          className={classNames("RichText--banner my-2 md:my-0", {
            "md:mx-auto": !date?.length && !ctaText?.length,
          })}
        />

        {ctaText && ctaLink?.url && (
          <span className="md:ml-auto">
            <Button bgColor="blue" text={ctaText} />
          </span>
        )}
      </Container>
    </Link>
  );
};

export default AnnouncementBanner;
