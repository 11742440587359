import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import { gsapScrollToElement } from "@utils";
import { useWindowSize } from "@hooks";
import { useAppState } from "@state/appState";
import TimelineSlide from "./TimelineSlide";

const Timeline = ({ items, className, style }) => {
  const [inView, setInView] = useState(false);
  const [active, setActive] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    idx: 0,
    animating: false,
  });
  const [{ layout }] = useAppState();

  const stage = useRef();

  const { innerHeight } = useWindowSize();

  const handleWheelUp = () => {
    if (currentItem.idx === 0 && !currentItem.animating) {
      setActive(false);
      document.body.style.overflow = "auto";
    } else {
      setCurrentItem(prev => {
        if (!prev.animating) {
          return {
            idx: Math.max(prev.idx - 1, 0),
            animating: true,
          };
        }

        return prev;
      });
    }
  };

  const handleWheelDown = () => {
    if (currentItem.idx === items.length - 1 && !currentItem.animating) {
      setActive(false);
      document.body.style.overflow = "auto";
    } else {
      setCurrentItem(prev => {
        if (!prev.animating) {
          return {
            idx: Math.min(prev.idx + 1, items.length - 1),
            animating: true,
          };
        }

        return prev;
      });
    }
  };

  const handleWheel = event => {
    if (!active) {
      return;
    }

    if (event.deltaY > 0) {
      handleWheelDown();
    } else {
      handleWheelUp();
    }
  };

  // useEffect(() => {
  //   if (inView) {
  //     document.body.style.overflow = "hidden";

  //     gsapScrollToElement({
  //       el: stage.current,
  //       duration: 0.2,
  //       onComplete: () => setActive(true),
  //     });
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }

  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [inView]);

  useEffect(() => {
    if (currentItem.animating) {
      setTimeout(() => {
        setCurrentItem(cur => {
          return {
            ...cur,
            animating: false,
          };
        });
      }, 1500);
    }
  }, [currentItem.animating]);

  return (
    // <InView
    //   onEnter={() => setInView(true)}
    //   onExit={() => setInView(false)}
    //   observerOptions={{
    //     threshold: 0.667,
    //   }}
    //   className={className}
    //   style={style}
    // >
    //   <div
    //     ref={stage}
    //     onWheel={layout.isTouchDevice ? undefined : handleWheel}
    //     className="relative overflow-hidden"
    //     style={
    //       typeof window !== "undefined"
    //         ? { height: layout.isTouchDevice ? "auto" : innerHeight }
    //         : { height: "100vh" }
    //     }
    //   >
    //     <div
    //       className={classNames({
    //         "absolute left-0 top-0 w-full": !layout.isTouchDevice,
    //       })}
    //       style={{
    //         height:
    //           typeof window !== "undefined"
    //             ? innerHeight * items.length
    //             : "100vh",
    //         transform: `translateY(${currentItem.idx * innerHeight * -1}px)`,
    //         transition: "transform 1s ease-in-out",
    //       }}
    //     >

    //     </div>
    //   </div>
    <div>
      {items.map((item, idx) => {
        return (
          <TimelineSlide
            key={item.id}
            isActive={currentItem.idx === idx}
            className={classNames({
              "flex items-center justify-center": true,
              // "absolute left-0 top-0 w-full": !layout.isTouchDevice,
            })}
            heading={item.heading}
            copy={item.copy}
            image={item.image}
            year={item.year}
            style={
              typeof window !== "undefined"
                ? { height: innerHeight }
                : { height: "100vh" }
            }
          />
        );
      })}
    </div>
    // </InView>
  );
};

Timeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ uid: PropTypes.number.isRequired })
  ).isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Timeline.defaultProps = {
  className: "",
  style: {},
};

export default Timeline;
