import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    prismicHeader {
      data {
        menu {
          title
          link {
            url
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { menu } = response.prismicHeader.data;

  return {
    menu: menu.map((item, idx) => ({
      uid: idx,
      title: item.title,
      url: item.link.url,
    })),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
