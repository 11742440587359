import Cookies from "js-cookie";

const appName = `${process.env.GATSBY_APP_NAME}`;

const hasCookie = (suffix, value = "true", expires = 365, reset = false) => {
  if (typeof window === "undefined") {
    return false;
  }

  if (Cookies.get(`${appName}_${suffix}`) === value) {
    if (reset) {
      Cookies.set(`${appName}_${suffix}`, value, { expires });
    }
    return true;
  }
  return false;
};

const setCookie = (suffix, value = "true", expires = 365) => {
  Cookies.set(`${appName}_${suffix}`, value, { expires });
};

export default { hasCookie, setCookie };
