import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/appState";

const HydrationFix = ({ children }) => {
  const [{ layout }] = useAppState();

  return <React.Fragment key={layout.hydrated}>{children}</React.Fragment>;
};

HydrationFix.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HydrationFix;
