import { useEffect } from "react";
import { useAppState } from "@state/appState";
import { useBreakpoints } from "@hooks";

// LayoutUpdater: this component returns nothing, and exists solely as a place to
// register hooks for updating

const hasWindow = typeof window !== "undefined";

const LayoutUpdater = () => {
  const [{ layout }, dispatch] = useAppState();
  const breakpoints = hasWindow ? useBreakpoints() : layout.breakpoints;

  useEffect(() => {
    dispatch({
      type: "layout.setBreakpoints",
      breakpoints,
    });
  }, Object.values(breakpoints));

  useEffect(() => {
    if (!layout.hydrated) {
      dispatch({
        type: "layout.setHydrated",
        hydrated: true,
      });
    }

    dispatch({
      type: "layout.setIsTouchDevice",
      isTouchDevice:
        "ontouchstart" in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0,
    });
  }, []);

  return null;
};

export default LayoutUpdater;
