import { registerMiddleware } from "@designbycosmic/cosmic-react-state-management";
import { cookies } from "@utils";

const { hasCookie, setCookie } = cookies;

const reducer = (state, action) => {
  switch (action.type) {
    case "gdpr.accept":
      return {
        ...state,
        accepted: true,
      };

    default:
      return state;
  }
};

registerMiddleware({
  actionType: "gdpr.accept",
  func: () => {
    setCookie("gdpr");
  },
});

const initialState = {
  accepted: hasCookie("gdpr", "true", true),
};

export default {
  reducer,
  initialState,
};
