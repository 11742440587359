/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Text, Image, RichText } from "@base";
import classNames from "classnames";
import { InView } from "@designbycosmic/cosmic-react-common-components";

const TimelineSlide = ({
  year,
  heading,
  copy,
  image,
  // isActive,
  className,
  style,
}) => {
  const [isActive, setIsActive] = useState(false);

  // need this to determine line height better
  const isMacLike =
    typeof window !== "undefined"
      ? /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
      : true;

  return (
    <div
      className={`${className} relative h-full overflow-hidden flex flex-grow`}
      style={style}
    >
      {/* mobile hack fo inview */}
      <InView
        onEnter={() => {
          setIsActive(true);
        }}
        observerOptions={{
          threshold: 0.5,
          margin: 0.25,
        }}
        unobserveAfterEntry
        // ref={observer}
        // style={{ ...style, ...initialStyle }}
        className="absolute inset-0 h-64 z-0 pointer-events-none overflow-hidden"
      />
      <div className="absolute inset-0 -my-px z-0">
        <div className="fade-to-black transform rotate-180 absolute bottom-0 left-0 right-0 z-10 h-full opacity-70 sm:opacity-50 sm:h-64 min-h-1/3-screen" />
        <div className="absolute inset-0 -my-px z-0">
          <Image {...image} fill placeholder={false} lazyLoad={false} />
        </div>
      </div>
      <div className="relative z-10 flex-grow flex-col md:px-6 lg:px-12 justify-end h-full flex text-white relative w-full mx-auto max-w-xl">
        <div className="flex-grow flex ml-12 -mb-6 sm:-mb-12 md:-mb-24">
          <div className="flex-grow h-full w-0 border-l-2 border-white " />
        </div>
        <div className="relative flex flex-col w-full p-6 sm:py-12 md:py-24 justify-end items-start">
          <div className="flex items-stretch -mx-2">
            <div className="flex flex-col">
              <div className="flex-shrink-0 border-2 flex items-center justify-center w-16 h-16 text-lg rounded-full bg-gray px-2">
                {year}
              </div>
              <div className="flex-grow flex ml-8 -mb-6 sm:-mb-12 md:-mb-24">
                <div className="flex-grow h-full w-0 border-l-2 border-white " />
              </div>
            </div>
            <div className="px-2 pt-3" style={{ textShadow: "0 0 30px black" }}>
              <div>
                {heading && (
                  <Text
                    tag="h2"
                    style={{
                      lineHeight: isMacLike ? "2.5rem" : "3rem",
                      marginTop: "-.15rem",
                    }}
                  >
                    {heading}
                  </Text>
                )}
                {copy && (
                  <RichText
                    html={copy}
                    size="lg"
                    className={classNames({
                      "mt-2": heading,
                    })}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TimelineSlide.propTypes = {
  heading: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

TimelineSlide.defaultProps = {
  className: "",
  style: {},
};

export default TimelineSlide;
