import React from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, imageProps } from "@base";
import { Link } from "@atoms";
import { AnimateIn } from "@ui";

const ArticleCardSmall = ({
  image,
  heading,
  shortTitle,
  summary,
  url,
  target,
  className,
  style,
}) => {
  return (
    <AnimateIn
      preset="fadeUpFast"
      className={`${className} relative`}
      style={style}
    >
      <div>
        <Link
          to={url}
          target={target}
          className="flex flex-col justify-end z-10 p-3"
        >
          <div>
            <Image {...image} aspectratio="4:3" lazyLoad={false} />
          </div>
          <Text tag="h4" className="text-black mt-3">
            {shortTitle || heading}
          </Text>
          <RichText
            html={`${summary.html}`}
            size="sm"
            className="text-black text-2xs readmore mt-2"
          />
          {/* <div className="mt-4">
          <Button bgColor="blue" text="Learn More" className="mr-2" />
        </div> */}
          {/* <Link to={url} /> */}
        </Link>
      </div>
    </AnimateIn>
  );
};

export const articleCardProps = {
  image: imageProps.isRequired,
  heading: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export const defaultArticleCardProps = {
  url: null,
  target: null,
  className: "",
  style: {},
};

ArticleCardSmall.propTypes = articleCardProps;

ArticleCardSmall.defaultProps = defaultArticleCardProps;

export default ArticleCardSmall;
