/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
// import propTypes from "prop-types";
import { Button } from "@atoms";
import { gsap } from "gsap";
import axios from "axios";

const EmailSignup = ({ cta, buttonText, interests, placeholder }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(
    interests?.length
      ? { interests: interests.map(interest => interest.slug) }
      : 0
  );

  const [checked, setChecked] = useState(interests.map(item => item.slug));

  const form = useRef();
  const errorMessage = useRef();
  const successMessage = useRef();

  useEffect(() => {
    if (success) {
      gsap.to(successMessage.current, {
        opacity: 1,
        duration: 0.2,
        display: "flex",
        // easing: "back",
      });
      gsap.to(form.current, { opacity: 0, pointerEvents: "none" });
    }
  }, [success]);

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  useEffect(() => {
    setFormData({ ...(formData || {}), interests: checked });
  }, checked);

  const submitForm = () => {
    if (formData && !success) {
      axios
        .post(`/.netlify/functions/mailchimp`, JSON.stringify(formData), {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(res => {
          if (!res.data.error) {
            // this.$gtm.push({ event: "emailSignUp" });
            setSuccess(true);
            return;
          }
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        });
    } else {
      setError("Please fill out all required fields");
    }
  };

  return (
    <div className="relative">
      <div ref={errorMessage} className="bg-blue-2 rounded h-0 overflow-hidden">
        <div className="p-3">{error}</div>
      </div>
      <div
        ref={successMessage}
        className="absolute rounded inset-0 bg-blue text-white justify-center items-center p-3 pointer-events-none opacity-0 hidden z-0"
      >
        You have successfully subscribed to updates.
      </div>
      <div className="text-lg font-sans mb-4">{cta}</div>
      <form
        ref={form}
        name="contact"
        onSubmit={e => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="flex flex-wrap relative z-0 sm:flex-nowrap justify-left items-center mt-3 mb-4 sm:mb-0">
          <div className="w-full max-w-2xs">
            <label htmlFor="email">
              <input
                className="font-sans py-1 w-full leading-1 text-xs bg-transparent border-b-2 border-blue focus:border-black text-black placeholder-gray"
                type="email"
                name="email"
                placeholder={placeholder || "Your Email Address"}
                onChange={e => {
                  setFormData({ ...(formData || {}), email: e.target.value });
                }}
              />
            </label>
          </div>
          <div className="hidden sm:block flex-shrink-0 mt-2 sm:mt-0 sm:pl-4">
            <Button
              bgColor="blue"
              type="submit"
              text={buttonText || "Sign Up"}
            />
          </div>
        </div>
        <div className="mt-2">
          <ul className="flex flex-wrap">
            {interests.map(item => {
              return (
                <li className="mr-2 mb-2">
                  <label
                    htmlFor={`${item.slug}`}
                    className="flex cursor-pointer"
                    onClick={() => {
                      if (checked?.includes(item.slug)) {
                        setChecked(checked.filter(slug => slug !== item.slug));
                      } else {
                        setChecked([...checked, item.slug]);
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      name={`${item.slug}`}
                      checked={checked?.includes(item.slug)}
                    />
                    <span className="flex items-center">{item.name}</span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="sm:hidden flex-shrink-0 mt-2 sm:mt-0">
          <Button bgColor="blue" type="submit" text={buttonText || "Sign Up"} />
        </div>
      </form>
    </div>
  );
};

EmailSignup.defaultProps = {};

EmailSignup.propTypes = {};

export default EmailSignup;
