import React from "react";
// import propTypes from "prop-types";
import { Text, Container } from "@base";
import { Button, Link } from "@atoms";
import { useAllArticlesData, useAllPressReleasesData } from "@staticQueries";
import ArticleCard from "./ArticleCard";

const LatestNews = ({ heading, emailHeading, emailCta, articles, blog }) => {
  const _articles = useAllArticlesData();
  const _releases = useAllPressReleasesData();

  const news =
    articles ||
    [..._articles, ..._releases]
      .sort(
        (a, b) =>
          new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
      )
      .slice(0, 3);
  return (
    <div
      className={`relative min-h-1/2-screen py-6 md:py-12 ${
        !blog ? "bg-white" : "bg-gray-0"
      }`}
    >
      <div className="relative z-10">
        <Container>
          <Text tag={!blog ? "h2" : "h3"} className="text-gray mb-6 lg:mb-10">
            {heading || "Latest News"}
          </Text>
          <div className="w-full mb-3">
            <div className="flex flex-wrap -mx-3">
              {news.map(item => (
                <div
                  key={item?.uid}
                  className="px-3 w-full sm:w-1/3 mb-6 sm:mb-0"
                >
                  <ArticleCard
                    {...item}
                    className="mb-2"
                    variant={blog ? "blog" : "default"}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-center mt-12">
            <Link to="/news/">
              <Button
                bgColor="blue"
                text={blog ? "All Articles" : "Visit Newsroom"}
              />
            </Link>
          </div>
        </Container>
      </div>
    </div>
  );
};

LatestNews.defaultProps = {};

LatestNews.propTypes = {};

export default LatestNews;
