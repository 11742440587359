/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container } from "@base";
import { Link, Button } from "@atoms";
import moment from "moment";

const Documents = ({ pressReleases, presentations, events, secFilings }) => {
  return (
    <div className="py-12 text-gray-dark bg-white">
      <Container hasMorePadding>
        <div className="grid grid-cols-1 gap-6 lg:gap-12 lg:gap-12 sm:grid-cols-3">
          <div>
            <h3 className="font-sans text-xl mb-4">Press Releases</h3>
            <ul className="font-sans">
              {!!pressReleases?.list?.length &&
                pressReleases.list.map(item => {
                  const { date, description, url } = item;
                  return (
                    <li className="my-6 text-xs">
                      <Link to={url} className="group leading-1.5">
                        {moment(date).format("MMMM D, YYYY")}
                        <span className="block transition text-blue duration-333 group-hover:text-white">
                          {description}
                        </span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
            {pressReleases?.link?.url && (
              <div className="mt-6">
                <Link to={pressReleases.link.url}>
                  <Button
                    to={pressReleases.link.url}
                    text={pressReleases.link.text}
                    bgColor="blue"
                  />
                </Link>
              </div>
            )}
          </div>
          <div>
            <h3 className="font-sans text-xl mb-4">Presentations</h3>
            <ul className="font-sans">
              {!!presentations?.list?.length &&
                presentations.list.map(item => {
                  const { date, description, url } = item;
                  return (
                    <li className="my-6 text-xs">
                      <Link to={url} className="group leading-1.5">
                        {moment(date).format("MMMM D, YYYY")}
                        <span className="block transition text-blue duration-333 group-hover:text-white">
                          {description}
                        </span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
            {!!presentations?.link?.url?.length && (
              <div>
                <Link to={presentations.link.url}>
                  <Button text={presentations.link.text} bgColor="blue" />
                </Link>
              </div>
            )}
          </div>
          <div>
            <h3 className="font-sans text-xl mb-4">Events</h3>
            <ul className="font-sans">
              {!!events?.list?.length &&
                events.list.map(item => {
                  const { date, description, url } = item;
                  return (
                    <li className="my-6 text-xs">
                      <Link to={url} className="group leading-1.5">
                        {moment(date).format("MMMM D, YYYY")}
                        <span className="block transition text-blue duration-333 group-hover:text-white">
                          {description}
                        </span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
            {!!events?.link?.url?.length && (
              <div>
                <Link to={events.link.url}>
                  <Button
                    to={events.link.url}
                    text={events.link.text}
                    bgColor="blue"
                  />
                </Link>
              </div>
            )}
          </div>
          {!!secFilings?.list?.length && (
            <div>
              <h3 className="font-sans text-xl mb-4">SEC Filings</h3>
              <ul className="font-sans">
                {secFilings.list.map(item => {
                  const { date, description, url } = item;
                  return (
                    <li className="my-6 text-xs">
                      <Link to={url} className="group leading-1.5">
                        {moment(date).format("MMMM D, YYYY")}
                        <span className="block transition text-blue duration-333 group-hover:text-white">
                          {description}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              {!!secFilings?.link?.url?.length && (
                <div>
                  <Link to={secFilings.link.url}>
                    <Button
                      to={secFilings.link.url}
                      text={secFilings.link.text}
                      bgColor="blue"
                    />
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

Documents.defaultProps = {};

Documents.propTypes = {};

export default Documents;
