/**
 * `resolveSlices` is used to transform an array of Prismic slices.
 * You will likely need to create a `case` for each slice that you define in Prismic.
 *
 * @param {object[]} body The array of slices
 *
 */

const resolveSlices = body => {
  let timeline;
  const timelineIndex = body.findIndex(
    block => block.sliceType === "timeline_item"
  );
  if (timelineIndex) {
    timeline = body
      .filter(block => block.sliceType === "timeline_item")
      .map(block => {
        return {
          id: block.id,
          image: block.primary.image,
          heading: block.primary.heading,
          copy: block.primary.descriptor.html,
          year: block.primary.year,
          type: block.sliceType,
        };
      });
  }
  const resolved = body
    .filter(block => block.primary || block.items)
    .map((block, i) => {
      const { id, sliceType, primary, items } = block;
      switch (sliceType) {
        // full_bleed_image
        case "full_bleed_image":
          return {
            id,
            type: sliceType,
            image: primary.image,
            heading: primary.heading,
            copy: primary.descriptor.html,
            bw: primary.imageColor,
            textY: primary.textVerticalPosition,
            textX: primary.textHorizontalPosition,
            darkText: primary.textColor,
            size: primary.size,
            buttons: items.map(b => {
              return {
                color: b.color,
                text: b.buttonLabel,
                link: b.buttonLink.url,
              };
            }),
          };
        // full_bleed_video
        case "full_bleed_video":
          return {
            id,
            type: sliceType,
            videoUrl: primary.vimeoUrl,
            preserveAspectRatio: primary.preserveAspectRatio,
            showControls: primary.showControls,
            autoplay: primary.autoplay,
            heading: primary.heading,
            copy: primary.descriptor.html,
            textY: primary.textVerticalPosition,
            textX: primary.textHorizontalPosition,
            darkText: primary.textColor,
            placeholder: primary.placeholderImage,
            buttons: items.map(b => {
              return {
                color: b.color,
                text: b.buttonLabel,
                url: b.buttonLink.url,
              };
            }),
            forcePlay: i === 0 && primary.autoplay,
          };
        case "image___copy_quote":
          return {
            id,
            image: primary.image,
            heading: primary.heading,
            copy: primary.copy.html,
            quote: primary.quote.html,
            citation: primary.citation.html,
            type: sliceType,
            altLayout: primary.imagePosition,
          };
        case "image___copy___icons":
          return {
            id,
            image: primary.image,
            heading: primary.heading,
            copy: primary.copy.html,
            icons: items.map(item => {
              return {
                code: item.icon.document?.data.svgCode.text,
                caption: item.caption,
              };
            }),
            type: sliceType,
            altLayout: primary.imagePosition,
          };
        case "img___copy_w__links":
          return {
            id,
            image: primary.image,
            heading: primary.heading,
            copy: primary.copy.html,
            links: items.map(item => {
              return {
                text: item.buttonText,
                url: item.link.url,
              };
            }),
            type: sliceType,
            altLayout: primary.imagePosition,
          };
        // case "timeline_item":
        //   return {
        //     id,
        //     image: primary.image,
        //     heading: primary.heading,
        //     copy: primary.descriptor.html,
        //     year: primary.year,
        //     type: sliceType,
        //   };
        case "logo_grid":
          return {
            id,
            logos: items,
            heading: primary.heading,
            copy: primary.descriptor.html,
            backgroundImage: primary.backgroundImage,
            type: sliceType,
          };
        default:
          return null;
      }
    });
  if (timeline.length) {
    resolved.splice(timelineIndex, 0, { type: "timeline", timeline });
  }
  return resolved.filter(block => block !== null);
};

export default resolveSlices;
