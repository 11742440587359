import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { gsap, Expo } from "gsap";

const AnimatedLine = ({
  x1,
  x2,
  y1,
  y2,
  strokeWidth,
  triggerAnimation,
  duration,
  className,
  style,
}) => {
  const line = useRef();

  useEffect(() => {
    if (triggerAnimation && line.current) {
      gsap.fromTo(
        line.current,
        duration,
        {
          drawSVG: 0,
          opacity: 1,
        },
        {
          drawSVG: "100%",
          ease: Expo.easeOut,
        }
      );
    }
  }, [triggerAnimation]);

  return (
    <svg className={className} style={style}>
      <line
        ref={line}
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        strokeWidth={strokeWidth}
        opacity={0}
      />
    </svg>
  );
};

AnimatedLine.propTypes = {
  x1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  x2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  y1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  y2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  strokeWidth: PropTypes.number,
  triggerAnimation: PropTypes.bool,
  duration: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

AnimatedLine.defaultProps = {
  strokeWidth: 1.5,
  triggerAnimation: false,
  duration: 1,
  className: "",
  style: {},
};

export default AnimatedLine;
