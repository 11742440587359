import React from "react";
import PropTypes from "prop-types";
import { Text, Icon } from "@base";
import { Link } from "@atoms";
import { useAppState } from "@state/appState";
import classNames from "classnames";

const BackLink = ({ url, text, disableBrowserBack, className, style }) => {
  const [{ navigation }] = useAppState();

  return (
    <Link
      to={url}
      useHistoryBack={navigation.previousPath === url && !disableBrowserBack}
      className={`flex items-center hover:opacity-75 transition duration-166 ease-in-out ${className}`}
      style={style}
    >
      <Icon
        name="arrow2"
        className={classNames({
          "mr-3 text-gray-1 w-4": true,
        })}
      />
      <Text tag="span" use="button">
        {text}
      </Text>
    </Link>
  );
};

BackLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disableBrowserBack: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

BackLink.defaultProps = {
  disableBrowserBack: false,
  className: "",
  style: {},
};

export default BackLink;
