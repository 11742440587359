import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@base";
import Link from "./Link";

const SocialIcon = ({ name, url, className, style }) => {
  return (
    <Link
      to={url}
      target="_blank"
      className={`w-6 h-6 rounded-full bg-gray hover:opacity-75 transition duration-166 ease-in-out ${className}`}
      style={style}
    >
      <Icon
        name={name}
        className="w-full h-full rounded-full text-gray-lighter"
      />
    </Link>
  );
};

SocialIcon.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

SocialIcon.defaultProps = {
  className: "",
  style: {},
};

export default SocialIcon;
