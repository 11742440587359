import React from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, imageProps, Icon } from "@base";
import { Link } from "@atoms";
import { AnimateIn } from "@ui";

const ArticleCardBlog = ({
  image,
  logo,
  heading,
  shortTitle,
  summary,
  url,
  target,
  className,
  style,
  type,
  author,
  categories,
}) => {
  return (
    <AnimateIn
      preset="fadeUpFast"
      className={`${className} h-full relative`}
      style={style}
    >
      <Link
        to={url}
        target={target}
        className="flex group transform hover:-translate-y-1 transition duration-166 h-full flex-col flex-growp-3 bg-white shadow-xl hover:shadow-2xl"
      >
        <Image {...image} aspectratio="8:5" />

        <div className="p-4 pb-1">
          {categories?.length > 0 && (
            <div className="mb-3">
              <Text
                tag="label"
                use="span"
                className="uppercase text-blue tracking-wider font-bold leading-1.2"
              >
                {categories[0]}
              </Text>
            </div>
          )}

          {(shortTitle || heading) !== "Press Release" && (
            <Text tag="h4" use="span" className="text-lg sm:text-xl font-sans">
              {shortTitle || heading}
            </Text>
          )}
          {(shortTitle || heading) === "Press Release" && (
            <h4
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: summary?.html }}
              className="text-xl sm:text-lg"
            />
          )}

          {author !== null && (
            <div className="flex flex-col leading-1 mt-2">
              <Text tag="span" use="p">
                {author?.name}
              </Text>
              <Text tag="span" use="p">
                {author?.company
                  ? `${author?.title}, ${author?.company}`
                  : author?.title}
              </Text>
            </div>
          )}
        </div>
      </Link>
    </AnimateIn>
  );
};

export const articleCardProps = {
  image: imageProps.isRequired,
  heading: PropTypes.string.isRequired,
  url: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export const defaultArticleCardProps = {
  url: null,
  target: null,
  className: "",
  style: {},
};

ArticleCardBlog.propTypes = articleCardProps;

ArticleCardBlog.defaultProps = defaultArticleCardProps;

export default ArticleCardBlog;
