import React from "react";
import propTypes from "prop-types";
import { Image, RichText, Text } from "@base";
import classNames from "classnames";

const SliceImageCopyIcons = ({
  image,
  quote,
  citation,
  heading,
  copy,
  altLayout,
}) => {
  return (
    <div className="flex flex-wrap items-stretch items-center bg-black text-white relative py-12">
      <div className="absolute inset-0 z-0 opacity-10 sm:opacity-100">
        <Image {...image} fill />
      </div>
      <div
        className={classNames({
          "ml-auto": altLayout,
          "mr-auto": !altLayout,
          "w-full flex flex-col justify-center sm:w-1/2 md:w-2/5 xl:w-1/3 p-8 md:p-12 lg:p-20 relative z-10": true,
        })}
        style={{ textShadow: "0 0 16px black" }}
      >
        {heading && (
          <Text tag="h2" className="mb-4">
            {heading}
          </Text>
        )}
        {copy && <RichText html={copy} />}
        <div className="flex mt-6 lg:mt-12 flex-wrap w-full">
          {quote && (
            <RichText
              html={quote}
              className="italic border-l border-blue px-6"
            />
          )}
          {citation && (
            <RichText html={citation} size="sm" className="px-6 w-full mt-4" />
          )}
        </div>
      </div>
    </div>
  );
};

SliceImageCopyIcons.defaultProps = {};

SliceImageCopyIcons.propTypes = {};

export default SliceImageCopyIcons;
