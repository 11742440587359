import React from "react";
import propTypes from "prop-types";
import { Image, RichText, Text, Container } from "@base";
import classNames from "classnames";

const SliceImageCopyIcons = ({ image, heading, copy, icons, altLayout }) => {
  return (
    <div className="flex flex-wrap items-stretch items-center pb-32 md:pb-56 lg:pb-64 relative bg-white">
      <div className="absolute opacity-25 md:opacity-100 inset-0 z-0">
        <Image {...image} fill />
      </div>
      <Container hasMorePadding>
        <div
          className={classNames({
            "w-full flex flex-col justify-center relative z-10 text-black p-8 md:pb-24 md:p-12 lg:pb-48 lg:p-20": true,
          })}
        >
          {heading && (
            <Text tag="h2" className="mb-6">
              {heading}
            </Text>
          )}
          {copy && <RichText html={copy} className="md:hidden" />}
          {copy && (
            <RichText
              html={copy}
              className="hidden text-gray md:block"
              style={{ columns: 2 }}
            />
          )}
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 mt-4 flex-wrap -mx-2">
            {icons.map(icon => {
              return (
                <div className="text-center px-2 uppercase font-bold text-2xs sm:text-3xs tracking-wide">
                  <div
                    className="w-full icon py-3 px-6 sm:px-3 text-gray"
                    dangerouslySetInnerHTML={{ __html: icon.code }}
                    style={{ maxWidth: "10rem" }}
                  />
                  <div className="text-gray-dark px-4 md:px-8 lg:px-3">
                    {icon.caption}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

SliceImageCopyIcons.defaultProps = {};

SliceImageCopyIcons.propTypes = {};

export default SliceImageCopyIcons;
