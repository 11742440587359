import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel, resolveImage, mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query AllArticlesQuery {
    allPrismicArticles {
      edges {
        node {
          uid
          data {
            publisher
            image {
              url
              dimensions {
                width
                height
              }
            }
            publisher_logo {
              url
              dimensions {
                width
                height
              }
            }
            heading
            date(formatString: "MMM DD, YYYY")
            short_title
            summary {
              html
            }
            external_link {
              url
              target
            }
          }
        }
      }
    }
  }
`;

const resolveArticle = article => {
  const { uid, data } = article.node;
  const { externalLink, publisherLogo, image, publisher, summary, ...rest } =
    keysToCamel(data);

  return {
    uid,
    ...rest,
    ...externalLink,
    image: resolveImage(image),
    target: "_blank",
    assets: [],
    type: "news",
    logo: resolveImage(publisherLogo),
    publisher,
    summary,
  };
};

const dataResolver = response => {
  const articles = response.allPrismicArticles.edges.map(resolveArticle);
  return articles.sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
