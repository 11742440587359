import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "@atoms";

const Paginator = ({
  items,
  page,
  limit,
  loadMore,
  itemRenderer,
  className,
}) => {
  const [canLoadMore, setCanLoadMore] = useState(false);

  let endIdx = page * limit + limit;
  if (endIdx >= items.length) {
    endIdx = items.length;
  }
  const paginatedResults = items.slice(0, endIdx);

  useEffect(() => {
    setCanLoadMore(endIdx < items.length);
  }, [items, page, limit]);

  const doLoadMore = () => {
    if (canLoadMore) {
      loadMore();
    }
  };

  return (
    <>
      <div className={className}>{itemRenderer(paginatedResults)}</div>

      {canLoadMore && (
        <div className="flex justify-center mt-8 sm:mt-12 md:mt-16 lg:mt-20">
          <Button
            bgColor="gray"
            onClick={doLoadMore}
            text="Load more"
            style={{ overflowAnchor: "none" }}
          />
        </div>
      )}
    </>
  );
};

Paginator.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  loadMore: PropTypes.func.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Paginator.defaultProps = {
  className: ",",
};

export default Paginator;
