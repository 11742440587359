/**
 * `linkResolver` is a function that maps Prismic documents to urls on the website.
 * In many cases, you can manually build a url directly in a component, but in some
 * cases (e.g., links rendered by a RichText field), links need to be built dynamically.
 *
 */

const linkResolver = () => doc => {
  const linkMap = {
    home_page: "/",
    about_page: "/about/",
    careers_page: "/careers/",
    news_index: "/news/",
    pages: `/${doc.uid}/`,
    articles: `/news/${doc.uid}/`,
    press_releases: `/news/${doc.uid}/`,
    blog_posts: `/blog/${doc.uid}/`,
    investor_relations: `/investor-relations/`,
    "404_page": `/404/`,
  };

  return linkMap[doc.type] || "/";
};

module.exports = linkResolver;
