import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useAppState } from "@state/appState";

const Carousel = ({
  items,
  infinite,
  initialSlide,
  onSlideChange,
  className,
}) => {
  const [height, setHeight] = useState("auto");
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const el = useRef({});
  const [{ layout }] = useAppState();

  useEffect(() => {
    if (initialSlide !== currentSlide) {
      el.current.slideTo(initialSlide);
    }
  }, [initialSlide]);

  const handleResize = () => {
    // this is a hack to set the height in px of the carousel
    // need timeout for carousel to render
    setTimeout(() => {
      if (el.current && el.current.rootComponent) {
        const firstSlide = el.current.rootComponent.querySelectorAll(
          ".alice-carousel__stage-item > div"
        )[0];
        setHeight(`${firstSlide.offsetHeight}px`);
      }
    }, 100);
  };

  const handleSlideChange = event => {
    setCurrentSlide(event.item);
    if (onSlideChange) {
      onSlideChange(event.item);
    }
  };

  return (
    <>
      <div
        role="button"
        className={classNames(
          {
            "Carousel relative outline-none focus:outline-none relative": true,
            "Carousel--overflow-visible px-5": layout.isTouchDevice,
          },
          className
        )}
        style={{
          height,
        }}
      >
        <AliceCarousel
          ref={el}
          mouseTrackingEnabled
          preventEventOnTouchMove
          dotsDisabled
          buttonsDisabled
          infinite={infinite}
          onInitialized={handleResize}
          onResized={handleResize}
          onSlideChanged={handleSlideChange}
          items={items}
        />
      </div>
    </>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  infinite: PropTypes.bool,
  initialSlide: PropTypes.number,
  onSlideChange: PropTypes.func,
  className: PropTypes.string,
};

Carousel.defaultProps = {
  items: [],
  infinite: true,
  initialSlide: 0,
  onSlideChange: null,
  className: "",
};

export default Carousel;
