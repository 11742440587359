import { useStaticQuery, graphql } from "gatsby";
import {
  keysToCamel,
  resolveObjectWithImage,
  mergePrismicPreview,
} from "@dataResolvers";

const query = graphql`
  query NewsIndexQuery {
    prismicNewsIndex {
      id
      uid
      data {
        hero {
          heading
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
          video_embed_id
        }
        news {
          heading
          description {
            html
          }
        }
        # press_releases {
        #   heading
        #   description {
        #     html
        #   }
        # }
        media_kit_heading
        media_kit_descriptor {
          html
        }
        media_kits {
          title
          description {
            html
          }
          link {
            url
            link_type
          }
          link_text
        }
        emails {
          email
          label
        }
        social_links {
          platform
          social_link {
            url
          }
        }
        meta {
          title
          description
          image {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const {
    uid,
    hero,
    news,
    meta,
    mediaKitHeading,
    mediaKitDescriptor,
    mediaKits,
    emails,
    socialLinks,
  } = keysToCamel(response.prismicNewsIndex.data);

  return {
    uid,
    hero: hero[0],
    news: news[0],
    // pressReleases: pressReleases[0],
    media: {
      heading: mediaKitHeading,
      descriptor: mediaKitDescriptor.html,
      kits: mediaKits.map(kit => {
        return {
          title: kit.title,
          description: kit.description.html,
          url: kit.link.url,
          download: kit.link.linkType === "Media",
          linkText: kit.linkText || "Download",
        };
      }),
    },
    contact: {
      emails,
      social: socialLinks.map(link => {
        return { platform: link.platform, url: link.socialLink.url };
      }),
    },
    meta: resolveObjectWithImage(meta[0]),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
