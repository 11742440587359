import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, imageProps, Icon } from "@base";
import { Link } from "@atoms";
import { AnimateIn } from "@ui";
import gsap from "gsap";
import moment from "moment";
import { useDefaultBlogImageData } from "@staticQueries";

const ArticleCardSmall = ({
  image,
  // logo,
  heading,
  shortTitle,
  summary,
  url,
  target,
  className,
  style,
  publisher,
  showDate,
  date,
  // type,
}) => {
  const { defaultImage } = useDefaultBlogImageData();
  const card = useRef();
  useEffect(() => {
    gsap.to(card.current, { opacity: 1, duration: 0.5 });
  }, []);
  return (
    <div ref={card} className="opacity-0">
      <Link
        to={url}
        target={target}
        className="flex items-center flex-wrap sm:flex-nowrap group transform transition duration-166 h-full text-black border-b-2 border-beige hover:border-blue hover:text-blue transition duration-333 py-6"
      >
        <div className="w-full sm:w-1/4 sm:max-w-xxs flex-shrink-0">
          <Image {...(image || defaultImage)} aspectratio="8:5" />
        </div>

        <div className="w-full sm:w-auto mt-3 sm:mt-0 flex-grow sm:pl-6">
          {publisher && (
            <div className="text-xs font-bold mb-1 uppercase text-blue">
              {publisher}
            </div>
          )}
          {!publisher && showDate && (
            <div className="text-xs font-bold mb-1 uppercase text-blue">
              {moment(date).format("MMMM D, YYYY")}
            </div>
          )}
          {(shortTitle || heading) !== "Press Release" && (
            <h4 className="text-xl sm:text-lg">{shortTitle || heading}</h4>
          )}
          {(shortTitle || heading) === "Press Release" && (
            <h4
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: summary?.html }}
              className="text-xl sm:text-lg"
            />
          )}
        </div>
        {/* <div className="mt-4">
          <Button bgColor="blue" text="Learn More" className="mr-2" />
        </div> */}
        {/* <Link to={url} /> */}
        {/* {type === "pr" && (
          <Icon
            name="jobyLogo"
            className="h-16 py-3 px-6 mt-auto mb-3"
            fitHeight
          />
        )}
        {type === "news" && (
          <div className="px-6 h-16 mt-auto mb-3">
            {logo && <Image {...logo} />}
          </div>
        )} */}
      </Link>
    </div>
  );
};

export const articleCardProps = {
  // image: imageProps.isRequired,
  heading: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export const defaultArticleCardProps = {
  url: null,
  target: null,
  className: "",
  style: {},
};

ArticleCardSmall.propTypes = articleCardProps;

ArticleCardSmall.defaultProps = defaultArticleCardProps;

export default ArticleCardSmall;
