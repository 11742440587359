import React from "react";
import { useAppState } from "@state/appState";
import { Text } from "@base";

const PreviewBanner = () => {
  const [{ layout }] = useAppState();

  if (layout.previewMode) {
    return (
      <div className="fixed bottom-0 left-0 right-0 bg-blue flex items-center h-12 px-4 z-60">
        <Text tag="label" className="text-white">
          This is a content preview
        </Text>
      </div>
    );
  }
  return null;
};

export default PreviewBanner;
