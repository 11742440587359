import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useAppState } from "@state/appState";
import { TimelineLite, Power2 } from "gsap";
import { Footer } from "@organisms";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import SEO, { seoProps } from "./SEO";

const DefaultLayout = ({ children, meta, ...rest }) => {
  const [{ layout }, dispatch] = useAppState();
  const el = useRef();

  const transitionTl = new TimelineLite();

  useEffect(() => {
    if (layout.transitionTo && layout.transitionTo !== rest.path) {
      transitionTl.to(el.current, {
        duration: 0.333,
        opacity: 0,
        ease: Power2.easeInOut,
        onComplete: () => {
          navigate(layout.transitionTo);
        },
      });
    } else if (!layout.transitionTo || layout.transitionTo === rest.path) {
      if (layout.showingMobileNav) {
        dispatch({ type: "layout.toggleMobileNav" });
      }

      dispatch({
        type: "layout.setTransition",
        transitionTo: null,
      });

      transitionTl.to(el.current, {
        duration: 0.5,
        opacity: 1,
        ease: Power2.easeIn,
        onComplete: () => {
          // avoid css transform/fixed position issues
          if (el.current) {
            el.current.style.transform = "none";
          }
          if (typeof window !== "undefined" && layout.transitionTo) {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
          }
        },
      });
    }
  }, [layout.transitionTo, rest.path]);

  // todo: remove this
  // if (typeof window !== "undefined") {
  //   if (window.navigator && navigator.serviceWorker) {
  //     navigator.serviceWorker.getRegistrations().then(registrations => {
  //       // eslint-disable-next-line no-restricted-syntax
  //       for (const registration of registrations) {
  //         registration.unregister();
  //       }
  //     });
  //   }
  // }
  //

  return (
    <>
      <SEO {...meta} />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div
        className={classNames({
          "flex-1 flex-grow flex flex-col": true,
          "pb-12": layout.previewMode,
        })}
      >
        <div
          ref={el}
          style={{ opacity: 0 }}
          className="flex-1 flex-grow flex flex-col"
        >
          <div className="flex-grow">{children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.shape(seoProps).isRequired,
};

export default DefaultLayout;
