import React from "react";
import propTypes from "prop-types";
import { Container, Text } from "@base";
import { EmailSignUpExpanded } from "@molecules";
import { formatPhone } from "@utils";

const InvestorSignUp = ({ heading, email, signUp, phone }) => {
  return (
    <div className="bg-white py-12 sm:py-24">
      <Container>
        <div className="flex flex-col sm:flex-row gap-12 sm:gap-4">
          <div className="w-full sm:w-1/2 flex flex-col sm:items-center sm:justify-center">
            <div>
              <div>
                <Text tag="h2" className="mb-6">
                  {heading}
                </Text>
              </div>
              <div>
                <div>
                  <a
                    className="font-bold text-blue text-lg hover:text-black transition duration-333"
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </div>
                <div className="mt-4">
                  <a
                    className="font-bold text-blue hover:text-black transition duration-333"
                    href={`tel:+1${phone}`}
                  >
                    {formatPhone(phone)}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 flex flex-col items-center justify-center">
            <div className="w-full">
              <EmailSignUpExpanded {...signUp} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

InvestorSignUp.defaultProps = {};

InvestorSignUp.propTypes = {};

export default InvestorSignUp;
