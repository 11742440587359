import React from "react";
import { useAppState } from "@state/appState";
import { imageProps } from "@base";
import HeroBase from "./HeroBase";

const HeroSmall = ({ image }) => {
  const [{ layout }] = useAppState();

  return (
    <HeroBase
      image={image}
      height="50vh"
      style={{ marginTop: layout.breakpoints.md ? 100 : 64 }}
    />
  );
};

HeroSmall.propTypes = {
  image: imageProps.isRequired,
};

export default HeroSmall;
