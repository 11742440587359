import React from "react";
import PropTypes from "prop-types";
import Base from "./ButtonBase";
import Default from "./ButtonDefault";
import UnStyled from "./ButtonUnStyled";

const Button = ({ variant, ...rest }) => {
  switch (variant) {
    case "base":
      return <Base {...rest} />;
    case "default":
      return <Default {...rest} />;
    case "unStyled":
      return <UnStyled {...rest} />;

    default:
      return null;
  }
};

Button.propTypes = {
  variant: PropTypes.oneOf(["base", "default", "unStyled"]),
};

Button.defaultProps = {
  variant: "default",
};

export default Button;
