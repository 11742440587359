import React, { useEffect, useState, useRef } from "react";
// import propTypes from "prop-types";
import { Button } from "@atoms";
import { gsap } from "gsap";
import axios from "axios";

const EmailSignup = ({ cta }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);

  const form = useRef();
  const errorMessage = useRef();
  const successMessage = useRef();

  useEffect(() => {
    if (success) {
      gsap.to(successMessage.current, {
        opacity: 1,
        duration: 0.2,
        display: "flex",
        // easing: "back",
      });
      gsap.to(form.current, { opacity: 0, pointerEvents: "none" });
    }
  }, [success]);

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  const submitForm = () => {
    if (formData && !success) {
      axios
        .post(`/.netlify/functions/mailchimp`, JSON.stringify(formData), {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then(res => {
          if (!res.data.error) {
            // this.$gtm.push({ event: "emailSignUp" });
            setSuccess(true);
            return;
          }
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        });
    } else {
      setError("Please fill out all required fields");
    }
  };

  return (
    <div className="relative">
      <div ref={errorMessage} className="bg-blue-2 rounded h-0 overflow-hidden">
        <div className="p-3">{error}</div>
      </div>
      <div
        ref={successMessage}
        className="absolute rounded inset-0 bg-blue justify-center items-center p-3 pointer-events-none opacity-0 hidden z-0"
      >
        You have successfully subscribed to updates.
      </div>
      <form
        ref={form}
        name="contact"
        className="flex relative z-0 flex-nowrap justify-center items-center mt-3 -mx-2"
        onSubmit={e => {
          e.preventDefault();
          submitForm();
        }}
      >
        <p className="px-2 w-full">
          <label htmlFor="email">
            <input
              className="font-sans w-full py-1 leading-1 lg:w-64 text-xs bg-transparent border-b-2 border-blue focus:border-gray-dark placeholder-gray"
              type="email"
              name="email"
              placeholder="Your Email Address"
              onChange={e => {
                setFormData({ ...(formData || {}), email: e.target.value });
              }}
            />
          </label>
        </p>
        <p className="px-2 flex-shrink-0 mt-2 sm:mt-0">
          <Button bgColor="blue" type="submit" text={cta || "Sign Up"} />
        </p>
      </form>
    </div>
  );
};

EmailSignup.defaultProps = {};

EmailSignup.propTypes = {};

export default EmailSignup;
