import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text, Image, imageProps, RichText } from "@base";
import { AnimateIn, HydrationFix } from "@ui";
import { useWindowSize } from "@hooks";
import { useAppState } from "@state/appState";

const ImageCopySideBySide = ({
  image,
  heading,
  description,
  reverse,
  className,
  style,
}) => {
  const [{ layout }] = useAppState();
  const { innerHeight } = useWindowSize();

  return (
    <HydrationFix>
      <div
        className={classNames(
          {
            "flex flex-col md:flex-row": true,
            "md:flex-row-reverse": reverse,
          },
          className
        )}
        style={{ ...style, minHeight: innerHeight * 0.67 }}
      >
        <div className="w-full md:w-1/2 relative">
          <Image
            {...image}
            showLQIP
            ratio={layout.breakpoints.md ? undefined : 4 / 3}
          />
        </div>

        <div className="w-full md:w-1/2 flex items-center justify-center bg-gray-2">
          <AnimateIn
            preset="fadeUp"
            className="max-w-md p-8 md:p-12 lg:p-16 xl:p-24"
          >
            <Text tag="h2" className="text-white">
              {heading}
            </Text>

            <RichText
              html={description.html}
              size="lg"
              className="mt-4 text-white"
            />
          </AnimateIn>
        </div>
      </div>
    </HydrationFix>
  );
};

ImageCopySideBySide.propTypes = {
  image: imageProps.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  reverse: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

ImageCopySideBySide.defaultProps = {
  reverse: false,
  className: "",
  style: {},
};

export default ImageCopySideBySide;
