import tw from "../../tailwind.config";

// Get the definitions for a particular utility

const themeGetter = name => {
  return tw.theme[name];
};

const getTailwindUtility = name => {
  const utility = tw.theme[name] || tw.theme.extend[name];

  if (!utility) {
    throw new Error("Tailwind utility not found");
  }

  if (typeof utility === "function") {
    return utility(themeGetter);
  }

  return utility;
};

export default getTailwindUtility;
