import React from "react";
import PropTypes from "prop-types";
import Base from "./HeroBase";
import Large from "./HeroLarge";
import Small from "./HeroSmall";

const Button = ({ variant, ...rest }) => {
  switch (variant) {
    case "base":
      return <Base {...rest} />;
    case "large":
      return <Large {...rest} />;
    case "small":
      return <Small {...rest} />;

    default:
      return null;
  }
};

Button.propTypes = {
  variant: PropTypes.oneOf(["base", "large", "small"]),
};

Button.defaultProps = {
  variant: "large",
};

export default Button;
