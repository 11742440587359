/**
 *
 * This is a shortcut for using the basic page transition. It will generally serve well
 * across the app for intra-site links there may be a case where a link-specific animation
 * is neccesary, in that case, just use TransitionLink
 *
 * @param {string} to link to a page on the site, starting with /
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { useAppState } from "@state/appState";
import { useAssetData } from "@staticQueries";

const Link = ({
  to: _to,
  target,
  onClick,
  useHistoryBack,
  className,
  style,
  children,
  ...rest
}) => {
  const [, dispatch] = useAppState();

  const assets = useAssetData();

  let to = _to;

  if (assets.includes(_to)) {
    to = _to
      ?.replace(process.env.GATSBY_ASSET_BASE_URL, "/files")
      .replace(
        /([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})_/,
        ""
      );
  }

  const handleClick = event => {
    // Abort if user is opening link in a new tab via cmd-click or ctrl-click
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    // Take over link control
    event.preventDefault();

    // If using history back, trigger programatically and return
    if (useHistoryBack) {
      window.history.back();
      return;
    }

    // Do nothing if navigating to page we're already on
    if (to === window.location.pathname) {
      return;
    }

    if (onClick) {
      onClick();
    }

    dispatch({
      type: "navigation.navigatedFrom",
      path: window.location.pathname,
    });

    // Trigger page transition
    dispatch({
      type: "layout.setTransition",
      transitionTo: to,
    });
  };

  if (
    target === "_blank" ||
    to?.includes("/files/") ||
    (to?.match(/http(s):\/\//) && !to?.includes(process.env.GATSBY_SITE_URL))
  ) {
    return (
      <a
        {...rest}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        className={className}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      {...rest}
      className={className}
      style={style}
      to={to}
      onClick={handleClick}
    >
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func,
  useHistoryBack: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Link.defaultProps = {
  onClick: undefined,
  useHistoryBack: false,
  className: "",
  style: {},
  target: undefined,
};

export default Link;
