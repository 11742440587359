import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview, keysToCamel } from "@dataResolvers";

const query = graphql`
  query AnnouncementQuery {
    prismicGlobals {
      id
      data {
        announcement {
          date(formatString: "MMM DD, YYYY")
          copy {
            html
            raw
          }
          cta_text
          cta_link {
            url
            target
          }
          is_showing
        }
      }
    }
  }
`;

const generateId = (strings = []) =>
  strings.map(str => str.toLowerCase().replace(/ /g, "-")).join("-");

const dataResolver = ({ prismicGlobals }) => {
  const { announcement } = prismicGlobals.data;
  const { copy, ctaLink, ...rest } = keysToCamel(announcement[0]);

  return {
    ...rest,
    uid: generateId([copy?.raw?.[0]?.text, ctaLink?.url]),
    copy,
    ctaLink,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
