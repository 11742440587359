import React from "react";
import PropTypes from "prop-types";
import Default from "./ArticleCard";
import Featured from "./ArticleCardFeatured";
import Blog from "./ArticleCardBlog";

const ArticleCard = ({ variant, ...rest }) => {
  switch (variant) {
    case "default":
      return <Default {...rest} />;
    case "featured":
      return <Featured {...rest} />;
    case "blog":
      return <Blog {...rest} />;
    default:
      return null;
  }
};

ArticleCard.propTypes = {
  variant: PropTypes.oneOf(["default", "featured", "blog"]),
};

ArticleCard.defaultProps = {
  variant: "default",
};

export default ArticleCard;
