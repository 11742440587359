import { useStaticQuery, graphql } from "gatsby";
import {
  keysToCamel,
  resolveSlices,
  resolveObjectWithImage,
  mergePrismicPreview,
} from "@dataResolvers";

const query = graphql`
  query CareersPageQuery {
    prismicCareersPage {
      prismicId
      id
      data {
        # hero
        hero {
          heading
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
          video_embed_id
        }
        # main content
        body {
          ... on PrismicCareersPageBodyFullBleedImage {
            id
            primary {
              heading
              descriptor {
                html
              }
              image {
                dimensions {
                  height
                  width
                }
                url
              }
              image_color
              size
              text_horizontal_position
              text_vertical_position
              text_color
            }
            items {
              color
              button_label
              button_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicCareersPageBodyImageCopyIcons {
            slice_type
            id
            primary {
              heading
              image_position
              copy {
                html
              }
              image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              caption
              icon {
                document {
                  ... on PrismicIcon {
                    id
                    data {
                      svg_code {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicCareersPageBodyImgCopyWLinks {
            slice_type
            id
            primary {
              heading
              image_position
              copy {
                html
              }
              image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              button_text
              link {
                url
              }
            }
          }
          ... on PrismicCareersPageBodyFullBleedVideo {
            id
            primary {
              autoplay
              heading
              descriptor {
                html
              }
              preserve_aspect_ratio
              show_controls
              size
              text_horizontal_position
              text_vertical_position
              vimeo_url
              text_color
            }
            items {
              button_label
              button_link {
                url
              }
              color
            }
            slice_type
          }
          ... on PrismicCareersPageBodyTimelineItem {
            id
            slice_type
            primary {
              heading
              descriptor {
                html
              }
              year
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCareersPageBodyImageCopyQuote {
            id
            primary {
              image_position
              citation {
                html
              }
              copy {
                html
              }
              heading
              quote {
                html
              }
              image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicCareersPageBodyLogoGrid {
            id
            slice_type
            primary {
              heading
              descriptor {
                html
              }
              background_image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
            items {
              logo {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        # meta
        meta {
          title
          description
          image {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { uid, hero, body, meta } = keysToCamel(
    response.prismicCareersPage.data
  );

  return {
    uid,
    hero: hero[0],
    slices: resolveSlices(body),
    meta: resolveObjectWithImage(meta[0]),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
