import React from "react";
// import { useWindowSize } from "@hooks";
// import { useAppState } from "@state/appState";
import HeroBase from "./HeroBase";

const HeroLarge = props => {
  // const [{ layout }] = useAppState();
  // const { innerHeight } = useWindowSize();

  return (
    <HeroBase
      {...props}
      // height={layout.breakpoints.md ? innerHeight : "vh"}
      // showArrow
    />
  );
};

export default HeroLarge;
