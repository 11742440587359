const reducer = (state, action) => {
  switch (action.type) {
    case "pagination.increment": {
      return {
        ...state,
        [action.group]: {
          ...state[action.group],
          page: state[action.group].page + 1,
        },
      };
    }

    default:
      return state;
  }
};

const initialState = {
  news: {
    page: 0,
  },
};

export default {
  reducer,
  initialState,
};
