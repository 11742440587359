import React from "react";
import propTypes from "prop-types";
import { Text, Icon, Container } from "@base";
import { Link } from "@atoms";

const Contact = ({ heading, emails, social }) => {
  return (
    <div className="bg-white py-12">
      <Container hasMorePadding>
        <Text tag="h2" className="mb-6">
          {heading}
        </Text>
        <ul className="grid md:grid-cols-3 gap-6 lg:gap-12">
          {emails.map(email => {
            return (
              <li>
                <div className="text-xs mb-4">{email.label}</div>
                <a
                  className="font-bold text-blue hover:text-black transition duration-333"
                  href={`mailto:${email.email}`}
                >
                  {email.email}
                </a>
              </li>
            );
          })}
          <li>
            <div className="text-xs mb-2">Social</div>
            <div>
              <ul className="flex -mx-1">
                {social.map(link => {
                  return (
                    <li className="px-1">
                      <Link to={link.url} target="_blank">
                        <Icon
                          name={link.platform}
                          className="w-6 h-6 p-1 bg-blue text-white hover:bg-black transition duration-333 rounded-full flex items-center justify-center"
                        />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        </ul>
      </Container>
    </div>
  );
};

Contact.defaultProps = {
  heading: "Contact",
};

Contact.propTypes = {};

export default Contact;
