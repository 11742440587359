import React, { useState, useEffect } from "react";
// import propTypes from "prop-types";
import { Text, Container } from "@base";
import { Button } from "@atoms";
import {
  // useNewsIndexData,
  useAllArticlesData,
  useAllPressReleasesData,
  useAllBlogPostsData,
  // useAllPressKitsData,
} from "@staticQueries";
import { ArticleListCard } from "@molecules";
import classNames from "classnames";
import gsap from "gsap";

const LatestNews = ({ heading }) => {
  const articles = useAllArticlesData().sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );
  const posts = useAllBlogPostsData().sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );
  const releases = useAllPressReleasesData().sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );

  const [showing, setShowing] = useState(5);
  const [type, setType] = useState("releases");

  useEffect(() => {
    setShowing(5);
  }, [type]);

  const news = { articles, releases, posts };

  return (
    <div className="relative min-h-1/2-screen bg-white-1 py-12">
      <div className="absolute inset-0 fade-to-white z-0" />
      <div className="relative z-10">
        <Container hasMorePadding>
          <Text tag="h2" className="text-black">
            {heading || "Latest News"}
          </Text>
          <div className="border-b-2 pt-2 text-sm border-beige lg:mt-4">
            <button
              type="button"
              onClick={e => {
                e.target.classList.add("border-blue");
                gsap.to(".index-item", {
                  opacity: 0,
                  stagger: 0.3 / showing / 2,
                  duration: 0.3 / showing,
                  y: 16,
                  onComplete: () => {
                    setType("releases");
                  },
                });
              }}
              className={classNames({
                "border-b-2 border-transparent transition duration-333 pb-2 mr-3 sm:mr-6 lg:mr-12 -mb-px": true,
                "border-blue": type === "releases",
              })}
            >
              Press Releases
            </button>
            <button
              type="button"
              onClick={e => {
                e.target.classList.add("border-blue");
                gsap.to(".index-item", {
                  opacity: 0,
                  stagger: 0.3 / showing / 2,
                  duration: 0.3 / showing,
                  y: 16,
                  onComplete: () => {
                    setType("posts");
                  },
                });
              }}
              className={classNames({
                "border-b-2 border-transparent transition duration-333 pb-2 mr-6 lg:mr-12 -mb-px": true,
                "border-b-2 border-blue": type === "posts",
              })}
            >
              Blog Posts
            </button>
            <button
              type="button"
              onClick={e => {
                e.target.classList.add("border-blue");
                gsap.to(".index-item", {
                  opacity: 0,
                  stagger: 0.3 / showing / 2,
                  duration: 0.3 / showing,
                  y: 16,
                  onComplete: () => {
                    setType("articles");
                  },
                });
              }}
              className={classNames({
                "border-b-2 border-transparent transition duration-333 pb-2 mr-6 lg:mr-12 -mb-px": true,
                "border-b-2 border-blue": type === "articles",
              })}
            >
              Media Coverage
            </button>
          </div>
          <div className="w-full mb-3">
            {news[type].slice(0, showing).map(item => (
              <div key={item.uid} className="w-full index-item">
                <ArticleListCard {...item} />
              </div>
            ))}
          </div>
          {showing < news[type].length && (
            <div className="mt-8 flex items-center justify-center">
              <Button
                bgColor="blue"
                type="submit"
                text="Load More"
                onClick={() => {
                  setShowing(showing + 5);
                }}
              />
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

LatestNews.defaultProps = {};

LatestNews.propTypes = {};

export default LatestNews;
