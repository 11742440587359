/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container, Text, RichText, Image } from "@base";
import { Link, Button } from "@atoms";
import classNames from "classnames";

const SliceImageCopyLinks = ({ image, heading, copy, altLayout, links }) => {
  return (
    <div className="flex flex-wrap items-stretch items-center bg-white relative">
      <div
        className={classNames(
          "relative h-1/2-screen md:h-auto md:absolute w-full md:w-1/2 top-0 bottom-0",
          { "left-0": !altLayout },
          { "right-0": altLayout }
        )}
      >
        <Image {...image} fill parallax />
      </div>
      <Container>
        <div className="flex">
          <div
            className={classNames(
              "w-full md:w-1/2 p-6 md:px-12 lg:px-16 py-24",
              { "ml-auto": !altLayout, "mr-auto": altLayout }
            )}
          >
            {heading && (
              <Text tag="h2" className="text-gray-dark mb-4">
                {heading}
              </Text>
            )}
            {copy && <RichText html={copy} />}
            <ul className="mt-6">
              {!!links?.length &&
                links.map(link => {
                  return (
                    <li>
                      <Link to={link.url}>
                        <Button bgColor="blue" text={link.text} />
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

SliceImageCopyLinks.defaultProps = {};

SliceImageCopyLinks.propTypes = {};

export default SliceImageCopyLinks;
