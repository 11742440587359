const resolveAuthor = blogAuthor => {
  if (!blogAuthor) return null;
  const { authorName, headshot, authorTitle, company } = blogAuthor;

  return {
    name: authorName,
    headshot,
    title: authorTitle,
    company,
  };
};

export default resolveAuthor;
