import { resolveAuthor } from "@dataResolvers";

const resolveFeaturedArticles = articles => {
  if (!articles || !Array.isArray(articles) || articles.length < 1) return null;

  return articles?.map(_article => {
    if (!_article?.article?.document?.id) return null;
    const {
      id: featuedId,
      url: featuedUrl,
      uid: featuedUid,
    } = _article?.article?.document;

    const {
      heading: featuredHeading,
      image: featuredImage,
      featuredAuthor,
      blogCategories,
    } = _article?.article?.document?.data;

    return {
      id: featuedId,
      url: featuedUrl,
      uid: featuedUid,
      heading: featuredHeading,
      image: featuredImage,
      author: resolveAuthor(featuredAuthor?.document?.data),
      categories: blogCategories?.split(",").map(cat => {
        return cat.trim();
      }),
    };
  });
};

export default resolveFeaturedArticles;
