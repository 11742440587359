import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getTailwindUtility } from "@utils";

// Container is used primarily to give content a max-width
// using the tailwind `container` class

const Container = ({
  hasPadding,
  hasMorePadding,
  hasAutoMargin,
  size,
  className: _className,
  children,
  style,
}) => {
  const wrapperClassName = classNames({
    "w-full": true,
    "px-6 sm:px-8 md:px-12 lg:px-16": hasPadding && !hasMorePadding,
    "px-6 sm:px-12 md:px-16 lg:px-24": hasMorePadding,
  });
  const checkSize = s => {
    if (s === "article") return false;
    if (s === "breakout-content") return false;
    if (s === "breakout") return false;
    return true;
  };

  const tMaxWidths = getTailwindUtility("maxWidth");
  const tMaxWidthsKeys = Object.keys(tMaxWidths)
    .filter(checkSize)
    .sort(a => {
      return parseInt(
        (tMaxWidths[a].max || tMaxWidths[a]).replace("px", ""),
        10
      );
    });

  const sizeUpIndex = tMaxWidthsKeys.findIndex(k => k === size) + 1;

  const sizeUp = tMaxWidthsKeys[sizeUpIndex] || size;

  const className = classNames(
    {
      "w-full": true,
      "mx-auto": hasAutoMargin,
      [`max-w-${size} xl:max-w-${sizeUp}`]: size !== "article",
      "max-w-article": size === "article",
    },
    _className
  );

  if (size === "full") {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={wrapperClassName} style={style}>
      <div className={className}>{children}</div>
    </div>
  );
};

Container.propTypes = {
  size: PropTypes.oneOf(Object.keys(getTailwindUtility("maxWidth"))),
  hasPadding: PropTypes.bool,
  hasAutoMargin: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Container.defaultProps = {
  size: "lg",
  hasPadding: true,
  hasAutoMargin: true,
  className: "",
  style: {},
};

export default Container;
