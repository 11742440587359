/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Text, Container, RichText } from "@base";

const CbQuote = ({ quote, author }) => {
  return (
    <Container size="content">
      <blockquote className="my-12 md:my-24 py-8 border-t-2 border-b-2 border-blue">
        {/* <Text
          tag="p"
          options={{
            size: "text-2xl",
            font: "font-sans",
            lineHeight: "leading-1.2"
          }}
          className="tracking-2 font-sans"
        >
          {quote.html}
        </Text> */}

        <RichText
          html={quote.html}
          className="text-2xl leading-1.2 tracking-2 font-sans"
          quote
        />

        <Text
          tag="figcaption"
          options={{
            size: "text-sm md:text-xs",
            font: "font-sans",
            weight: "font-bold",
          }}
          className="mt-6 uppercase tracking-2"
        >
          - {author}
        </Text>
      </blockquote>
    </Container>
  );
};

CbQuote.defaultProps = {};

CbQuote.propTypes = {};

export default CbQuote;
