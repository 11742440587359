import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import tinyColor from "tinycolor2";
import { getTailwindColor } from "@utils";

const Scrim = ({ position, color, distance, className, style }) => {
  const positionStyles = {
    top: {
      top: 0,
      left: 0,
      right: 0,
      height: distance,
    },
    bottom: {
      bottom: 0,
      left: 0,
      right: 0,
      height: distance,
    },
    left: {
      top: 0,
      bottom: 0,
      left: 0,
      width: distance,
    },
    right: {
      top: 0,
      bottom: 0,
      right: 0,
      width: distance,
    },
  };

  const gradientDirections = {
    top: "to bottom",
    bottom: "to top",
    left: "to right",
    right: "to left",
  };

  const gradientColor = color || getTailwindColor("black.default");

  return (
    <div
      className={classNames(
        {
          "transition duration-333 ease-in-out pointer-events-none": true,
          absolute: !className,
        },
        className
      )}
      style={{
        ...style,
        ...positionStyles[position],
        background: `
          linear-gradient(${gradientDirections[position]}, 
          ${gradientColor},
          ${tinyColor(gradientColor).setAlpha(0).toString()})
        `,
      }}
    />
  );
};

Scrim.propTypes = {
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]).isRequired,
  color: PropTypes.string,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Scrim.defaultProps = {
  color: null,
  distance: 150,
  className: "",
  style: {},
};

export default Scrim;
