import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview, resolveImage, keysToCamel } from "@dataResolvers";

const query = graphql`
  query DefaultBlogImageQuery {
    prismicGlobals {
      id
      data {
        default_blog_image {
          url
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { default_blog_image } = prismicGlobals.data;

  const resolved = {
    defaultImage: resolveImage(default_blog_image),
  };

  return keysToCamel(resolved);
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
