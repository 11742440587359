import React from "react";
import PropTypes from "prop-types";
import { Section, Container, Text, Image, imageProps, Icon } from "@base";
import { AnimateIn, VimeoBackgroundVideo } from "@ui";
import { useAppState } from "@state/appState";
import { useScrollPosition } from "@hooks";

const HeroBase = ({
  image,
  videoEmbedId,
  heading,
  height,
  showArrow,
  className,
  style,
}) => {
  const [{ layout }] = useAppState();
  const { scrollY } = useScrollPosition();

  return (
    <Section
      allowOverflow
      className={`flex items-center min-h-1/2-screen sm:min-h-2/3-screen md:min-h-screen justify-center ${className}`}
      style={{ ...style, height }}
    >
      {videoEmbedId && (
        <VimeoBackgroundVideo
          videoId={videoEmbedId}
          ratio={16 / 9}
          className="bg-black z-20"
          disableOnTouchDevice
        />
      )}

      {(!videoEmbedId || layout.isTouchDevice) && (
        <div className="absolute inset-0">
          <Image {...image} fill params={{ crop: "center" }} />
        </div>
      )}

      {heading && (
        <Container className="relative z-30">
          <AnimateIn preset="fadeUp">
            <div style={{ textShadow: "0 0 30px black" }}>
              <Text
                tag="h1"
                className="text-white text-center font-extralight"
                preserveWidows
              >
                {heading}
              </Text>
            </div>
          </AnimateIn>
        </Container>
      )}

      {showArrow && (
        <div
          className="fixed z-30 transition duration-666 ease-in-out"
          style={{
            bottom: "1.5rem",
            left: "50%",
            transform: "translateX(-50%)",
            opacity: scrollY < 5 ? 1 : 0,
          }}
        >
          <AnimateIn preset="fadeDown">
            <Icon name="Arrow" className="w-12 h-8 text-white" />
          </AnimateIn>
        </div>
      )}
    </Section>
  );
};

HeroBase.propTypes = {
  image: imageProps.isRequired,
  videoEmbedId: PropTypes.string,
  heading: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showArrow: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

HeroBase.defaultProps = {
  videoEmbedId: null,
  heading: null,
  showArrow: false,
  className: "",
  style: {},
};

export default HeroBase;
