import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel, resolveImage, mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query AllBlogPostsQuery {
    allPrismicBlogPosts {
      edges {
        node {
          uid
          url
          data {
            image {
              url
              dimensions {
                width
                height
              }
            }
            heading
            date(formatString: "MMM DD, YYYY")
            datetime
            short_title
            show_date
            summary {
              html
            }
            meta {
              title
              description
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

const resolveBlogPost = article => {
  const { uid, url, data } = article.node;
  const { image, summary, shortTitle, showDate, meta, ...rest } =
    keysToCamel(data);

  return {
    ...rest,
    uid,
    url,
    image: resolveImage(meta?.[0]?.image) || resolveImage(image),
    shortTitle,
    summary,
    showDate: showDate !== false,
    assets: [],
    type: "bp",
  };
};

const dataResolver = response => {
  const blogPosts = response.allPrismicBlogPosts.edges.map(resolveBlogPost);
  return blogPosts.sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
