import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state/appState";
import { Power4 } from "gsap";
import { useGdprData } from "@staticQueries";
import { RichText } from "@base";
import { tl } from "@timeline";
import { Button } from "@atoms";
import { loadGTM } from "@utils";

const GdprBanner = () => {
  // TODO: use global appState `layout.hydrated` here??
  const [hydrated, setHydrated] = useState(false);

  const banner = useRef();

  const [{ gdpr, layout }, dispatch] = useAppState();
  const { accepted } = gdpr;

  const { copy, ctaText, isShowing } = useGdprData();

  useEffect(() => {
    if ((!layout.gtmLoaded && accepted) || !isShowing) {
      dispatch({ type: "layout.loadGtm" });
      loadGTM();
    }
  }, [accepted]);

  useEffect(() => {
    setHydrated(true);
  }, []);

  // Show/Hide the gdpr banner based on gdpr acceptance
  useEffect(() => {
    if (!isShowing) {
      return;
    }

    if (accepted) {
      tl.to(banner.current, {
        duration: 1,
        opacity: 0,
        ease: Power4.easeOut,
      });

      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "gdpr-accepted", // used to trigger any events that need GDPR compliance
        gdprAccepted: true, // sets var so any subsequent events that need GDPR compliance can also run
      });
    } else {
      tl.fromTo(
        banner.current,
        1.75,
        { y: "100%", opacity: 0 },
        { y: 0, opacity: 1, ease: Power4.easeOut }
      );
    }
  }, [accepted, isShowing]);

  return (
    <div
      ref={banner}
      className={classNames({
        "fixed bottom-0 right-0 z-60 md:p-4 flex justify-end": true,
        "mb-12": layout.previewMode,
        "pointer-events-none": hydrated && accepted,
        "pointer-events-auto": hydrated && !accepted,
      })}
      style={{ opacity: 0 }}
    >
      <div className="flex items-center md:border-2 border-blue text-black justify-between bg-white p-4 max-w-sm">
        <div className="flex-1 flex items-center">
          <RichText html={copy.html} backgroundIsDark className="flex-1" />
        </div>

        <Button
          bgColor="blue"
          onClick={() => dispatch({ type: "gdpr.accept" })}
          className="ml-4"
          text={ctaText}
        />
      </div>
    </div>
  );
};

export default GdprBanner;
