import React from "react";
import PropTypes from "prop-types";

const List = ({ tag: Tag, children, className }) => {
  // Loop through children an add appropriate number if this
  // is an ordered list, or disc boolean if unordered list
  const items = React.Children.map(children, (child, idx) => {
    if (typeof child.type !== "undefined") {
      return React.cloneElement(child, {
        ...child.props,
        number: Tag === "ol" ? idx + 1 : null,
        isLast: idx === children.length - 1,
      });
    }
    return null;
  });

  return <Tag className={className}>{items}</Tag>;
};

List.propTypes = {
  tag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

List.defaultProps = {
  className: "",
};

export default List;
