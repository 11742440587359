/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container, RichText, Text, Image } from "@base";
import classNames from "classnames";

const CbBreakoutBox = ({
  content,
  image,
  caption,
  boxHeading,
  boxContent,
  swap,
  horizontal,
}) => {
  if (horizontal) {
    return (
      <Container size="lg">
        <div
          className={classNames(
            "my-16 flex flex-col md:flex-row items-start",
            { "justify-start": !swap },
            { "justify-end": swap }
          )}
        >
          <div
            className={classNames(
              "border-2 p-4 md:p-8 mb-8 w-full flex flex-col md:flex-row items-start"
            )}
          >
            <div className="w-full max-w-none md:max-w-breakout flex-grow w-full pr0 md:pr-8 items-start">
              {image && (
                <Image
                  {...image}
                  placeholder={false}
                  fitHeight
                  caption={caption?.html || null}
                />
              )}

              {boxHeading && (
                <Text
                  tag="h3"
                  use="span"
                  className="mt-6 mb-4 text-lg leading-1.2 text-blue font-bold uppercase tracking-wide"
                >
                  {boxHeading}
                </Text>
              )}
            </div>

            <RichText
              html={boxContent}
              className="article-rich-text text-gray-2 max-w-md w-full"
              article
            />
          </div>
        </div>
      </Container>
    );
  }
  return (
    <Container size="lg">
      <div
        className={classNames(
          "my-16 flex flex-col md:flex-row items-start",
          { "justify-start": !swap },
          { "justify-end": swap }
        )}
      >
        <div className="w-full md:max-w-breakout-content flex-initial">
          <RichText html={content} className="article-rich-text" article />
        </div>

        <div
          className={classNames(
            "border-2 p-4 md:p-8 mb-8 w-full max-w-none md:max-w-breakout flex-1 md:flex-none",
            { "order-first mr-0 md:mr-12": !swap },
            { "order-last ml-0 md:ml-12 mt-8 md:mt-0": swap }
          )}
        >
          {image && (
            <Image
              {...image}
              placeholder={false}
              fitHeight
              caption={caption?.html || null}
            />
          )}

          {boxHeading && (
            <Text
              tag="h3"
              use="span"
              className="mt-6 mb-4 text-lg leading-1.2 text-blue font-bold uppercase tracking-wide"
            >
              {boxHeading}
            </Text>
          )}

          <RichText
            html={boxContent}
            className="article-rich-text text-gray-2"
            article
          />
        </div>
      </div>
    </Container>
  );
};

CbBreakoutBox.defaultProps = {};

CbBreakoutBox.propTypes = {};

export default CbBreakoutBox;
