import React from "react";
import propTypes from "prop-types";
import { Container, Text, RichText } from "@base";
import { Link } from "@atoms";

const Overview = ({ heading, copy, downloads }) => {
  return (
    <div className="bg-white relative">
      <div className="bg-beige w-2/5 top-0 right-0 bottom-0 hidden sm:block absolute z-10" />
      <div className="relative z-10">
        <Container hasMorePadding>
          <div className="flex flex-wrap items-center">
            <div className="w-full sm:w-3/5 py-12 sm:py-24 sm:pr-12">
              {heading && (
                <Text tag="h3" className="mb-4">
                  {heading}
                </Text>
              )}
              {copy && <RichText html={copy} />}
            </div>
            <div className="w-full sm:w-2/5 xl:w-1/5 xl:ml-auto py-12 sm:py-24 xl:px-12 flex flex-col items-start sm:items-end sm:px-6">
              <ul className="flex flex-col gap-8 w-full">
                {downloads.map(d => {
                  return (
                    <li>
                      <Link
                        className="bg-white rounded-md shadow-2xl hover:shadow-xl flex flex-col items-center justify-center text-center text-gray-dark p-6 lg:py-12 transition duration-333 h-full group"
                        to={d.url}
                        download
                      >
                        <Text
                          tag="h4"
                          className="text-center font-semibold mb-2"
                        >
                          {d.text}
                        </Text>
                        <span className="text-xs text-blue transition font-bold duration-333 group-hover:text-black mt-2">
                          download
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

Overview.defaultProps = {};

Overview.propTypes = {};

export default Overview;
