import { createAppState } from "@designbycosmic/cosmic-react-state-management";
import layout from "./reducers/layout";
import gdpr from "./reducers/gdpr";
import announcement from "./reducers/announcement";
import pagination from "./reducers/pagination";
import navigation from "./reducers/navigation";
import videoModal from "./reducers/videoModal";

const reducer = {
  layout: layout.reducer,
  gdpr: gdpr.reducer,
  announcement: announcement.reducer,
  pagination: pagination.reducer,
  navigation: navigation.reducer,
  videoModal: videoModal.reducer,
};

const initialState = {
  layout: layout.initialState,
  gdpr: gdpr.initialState,
  announcement: announcement.initialState,
  pagination: pagination.initialState,
  navigation: navigation.initialState,
  videoModal: videoModal.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
