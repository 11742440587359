import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview, keysToCamel } from "@dataResolvers";

const query = graphql`
  query GdprQuery {
    prismicGlobals {
      id
      data {
        gdpr {
          copy {
            html
          }
          cta_text
          is_showing
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { gdpr } = prismicGlobals.data;
  return keysToCamel(gdpr[0]);
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
