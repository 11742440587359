import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text } from "@base";

const ListItem = ({ number, isLast, children, className, article }) => {
  return (
    <li
      className={classNames(
        {
          "relative mb-2": true,
          "pl-6": !!number,
          "pl-4": !number,
          "mb-2": !isLast,
          "pl-6": article,
        },
        className
      )}
    >
      <div
        className={classNames({
          "absolute left-0 top-0": true,
          "w-6": !!number,
          "w-4": !number,
        })}
      >
        {number ? (
          <Text
            tag="span"
            options={{ lineHeight: "leading-1.5" }}
            className={classNames({
              "font-sans": true,
              "text-blue font-bold": article,
            })}
          >
            {number}.
          </Text>
        ) : (
          <Text
            tag="span"
            options={{ lineHeight: "leading-1.5" }}
            className={classNames({
              "font-sans flex w-full items-center": true,
              "bold text-blue": article,
            })}
          >
            {"\u2022"}
          </Text>
        )}
      </div>

      <Text tag="span" use="li">
        {children}
      </Text>
    </li>
  );
};

ListItem.propTypes = {
  number: PropTypes.number,
  isLast: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ListItem.defaultProps = {
  number: null,
  isLast: false,
  className: "",
};

export default ListItem;
