import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon } from "@base";
import { LinkWrap } from "@ui";

const PageNavigationLink = ({ url, text, reverse }) => {
  return (
    <LinkWrap
      useLink={!!url}
      to={url}
      className={classNames({
        "flex items-center mx-2 group font-semibold": true,
        "opacity-25 pointer-events-none": !url,
        "flex-row-reverse": reverse,
      })}
    >
      <Icon
        name="arrow2"
        className={classNames({
          "w-8 h-8 p-2 mx-2 text-gray-1 transition duration-333 ease-in-out transform": true,
          "rotate-180": reverse,
          "rotate-270": !reverse,
        })}
      />
      <Text
        tag="span"
        use="button"
        className={classNames({
          "font-medium": true,
          "text-right": !reverse,
          "text-left": reverse,
        })}
      >
        {text}
      </Text>
    </LinkWrap>
  );
};

PageNavigationLink.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

PageNavigationLink.defaultProps = {
  url: null,
  reverse: false,
};

const PageNavigation = ({
  items,
  uid,
  prevText,
  nextText,
  className,
  style,
}) => {
  const currentIdx = items.findIndex(item => item.uid === uid);
  const { url: prevUrl } = items[currentIdx - 1] || {};
  const { url: nextUrl } = items[currentIdx + 1] || {};

  return (
    <div className={`flex space-x-16 ${className}`} style={style}>
      <PageNavigationLink url={prevUrl} text={prevText} />
      <PageNavigationLink url={nextUrl} text={nextText} reverse />
    </div>
  );
};

PageNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  uid: PropTypes.string.isRequired,
  prevText: PropTypes.string.isRequired,
  nextText: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

PageNavigation.defaultProps = {
  className: "",
  style: {},
};

export default PageNavigation;
