import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/appState";
import { Section, Container, Text, Image, imageProps, RichText } from "@base";
import { Link, Button, Scrim } from "@atoms";
import { AnimateIn, HydrationFix } from "@ui";
import { useWindowSize } from "@hooks";

const ImageCopyBlock = ({
  image,
  heading,
  description,
  copyPosition,
  ctaLink,
  ctaText,
  isInView,
  showImage,
  showScrim,
  className,
  style,
}) => {
  const [{ layout }] = useAppState();
  const { innerHeight } = useWindowSize();

  return (
    <HydrationFix>
      <Section
        className={classNames(
          {
            "flex justify-center": true,
            [`items-${copyPosition}`]: true,
          },
          className
        )}
        style={{
          ...style,
          minHeight: layout.breakpoints.md ? innerHeight : "90vh",
        }}
      >
        {showImage && <Image {...image} showLQIP />}

        {showScrim && (
          <Scrim distance="67%" position="bottom" className="absolute z-20" />
        )}

        <Container size="sm" className="relative z-30">
          <AnimateIn preset="fadeUp" triggerAnimation={isInView}>
            <Text tag="h2" className="text-white text-center">
              {heading}
            </Text>

            <RichText
              html={description.html}
              size="lg"
              className="mt-4 text-white"
            />

            {ctaText && ctaLink && (
              <div className="flex justify-center mt-4">
                <Link to={ctaLink.url} target={ctaLink.target}>
                  <Button bgColor="blue" text={ctaText} />
                </Link>
              </div>
            )}
          </AnimateIn>
        </Container>
      </Section>
    </HydrationFix>
  );
};

export const imageCopyBlockProps = {
  image: imageProps.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  ctaLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  ctaText: PropTypes.string,
  copyPosition: PropTypes.string.isRequired,
  isInView: PropTypes.bool,
  showImage: PropTypes.bool,
  showScrim: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

ImageCopyBlock.propTypes = imageCopyBlockProps;

ImageCopyBlock.defaultProps = {
  ctaText: null,
  ctaLink: null,
  isInView: undefined,
  showImage: true,
  showScrim: false,
  className: "",
  style: {},
};

export default ImageCopyBlock;
