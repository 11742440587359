// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-about-page-js": () => import("./../../../src/pageTemplates/AboutPage.js" /* webpackChunkName: "component---src-page-templates-about-page-js" */),
  "component---src-page-templates-careers-page-js": () => import("./../../../src/pageTemplates/CareersPage.js" /* webpackChunkName: "component---src-page-templates-careers-page-js" */),
  "component---src-page-templates-home-page-js": () => import("./../../../src/pageTemplates/HomePage.js" /* webpackChunkName: "component---src-page-templates-home-page-js" */),
  "component---src-page-templates-news-index-js": () => import("./../../../src/pageTemplates/NewsIndex.js" /* webpackChunkName: "component---src-page-templates-news-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/Preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-prismic-dynamic-queries-blog-post-query-js": () => import("./../../../src/prismic/dynamicQueries/BlogPostQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-blog-post-query-js" */),
  "component---src-prismic-dynamic-queries-page-query-js": () => import("./../../../src/prismic/dynamicQueries/PageQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-page-query-js" */),
  "component---src-prismic-dynamic-queries-press-release-query-js": () => import("./../../../src/prismic/dynamicQueries/PressReleaseQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-press-release-query-js" */)
}

