import React from "react";
import propTypes from "prop-types";

const Icon = ({ name, className: _className, fitHeight, _style }) => {
  if (name) {
    // eslint-disable-next-line global-require
    const Content = React.memo(require("@svg")[name] || name);
    return (
      <span
        className={`inline-block icon${
          fitHeight ? "--fit-height" : ""
        } ${_className}`}
        style={{ _style }}
      >
        <Content />
      </span>
    );
  }
  return null;
};

Icon.defaultProps = {
  fitHeight: false,
  className: "w-12 h-12",
};

Icon.propTypes = {
  fitHeight: propTypes.bool,
  name: propTypes.string.isRequired,
  className: propTypes.string,
};

export default Icon;
