import React from "react";
import PropTypes from "prop-types";
import { Link } from "@atoms";

// This is a helper component that will wrap an entire block of content
// in a Link if `useLink` is true, otherwise it wraps in a div. It's used
// on ArticleCards that can eithe be entirely clickable, or have a several
// clickable buttons

const LinkWrap = ({ useLink, children, className, style, ...rest }) => {
  if (useLink) {
    return (
      <Link {...rest} className={className} style={style}>
        {children}
      </Link>
    );
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

LinkWrap.propTypes = {
  useLink: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

LinkWrap.defaultProps = {
  className: "",
  style: {},
};

export default LinkWrap;
