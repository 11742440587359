/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container, Text, RichText, Image } from "@base";
import { Link, Button } from "@atoms";
import classNames from "classnames";

const CbImage = ({ image, caption, size }) => {
  const hasCaption = caption?.html.length && caption?.text !== "";

  return (
    <Container size={size === "full" ? "full" : "article"}>
      <div
        className={classNames(
          "",
          { "mt-10": hasCaption },
          { "my-10": !hasCaption },
          { "px-0 md:px-40": size === "narrower" }
        )}
      >
        <Image
          {...image}
          placeholder={false}
          fitHeight
          caption={hasCaption && size === "fit text" ? caption.html : null}
          actualFit
        />
      </div>
      {size !== "fit text" && (
        <Container size={size === "narrower" ? "full" : "article"}>
          <RichText html={caption.html} className="text-gray-2 pt-4" />
        </Container>
      )}
    </Container>
  );
};

CbImage.defaultProps = {};

CbImage.propTypes = {};

export default CbImage;
