/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Suspense, useRef, useEffect } from "react";
import "../../pcss/video.pcss";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import Image from "../base/Image";

const Video = ({
  url: _url,
  muted,
  autoplay,
  placeholder,
  play,
  controls,
  enterView,
  options: _options,
  fill,
  forcePlay,
  loop,
}) => {
  let url;
  const plyr = useRef();
  const player = useRef();
  const options = {
    muted,
    autoplay: autoplay && !enterView,
    controls,
    loop: { active: loop },
    ..._options,
  };
  const provider = _url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";
  if (provider === "vimeo " && !url.match(/vimeo\.com\/(\d+)/)) {
    return null;
  }

  if (provider === "youtube") {
    if (_url.match("embed")) {
      url = _url.split(/embed\//)[1].split('"')[0];
    } else {
      url = _url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
    }
  }

  if (provider === "vimeo") {
    url = _url.split("/").pop();
  }

  const handleEnter = () => {
    if (player.current) {
      player.current.play();
    }
  };

  useEffect(() => {
    if (player.current) {
      if (play) {
        player.current.play();
      } else {
        player.current.pause();
      }
    }
  }, [play]);

  if (typeof window !== "undefined" && url) {
    // eslint-disable-next-line global-require
    const Plyr = require("plyr");
    useEffect(() => {
      if (!player.current) {
        if (plyr.current && Plyr) {
          player.current = new Plyr(plyr.current);
          player.current.on("ready", event => {
            if (!play) {
              player.current.pause();
            }
            if (autoplay && forcePlay) {
              setTimeout(() => player.current.play(), 500);
            }
          });
        }
      }
    }, []);
    // set up the player
    return (
      <Suspense
        fallback={
          <div>
            <Image {...placeholder} />
          </div>
        }
      >
        <InView
          onEnter={handleEnter}
          observerOptions={{
            threshold: 0.01,
          }}
          unobserveAfterEntry
          className={
            fill ? "absolute inset-0 overflow-hidden w-full h-full" : ""
          }
        >
          <div className={`${fill ? "absolute inset-0 video-fill" : ""}`}>
            <div
              data-plyr-provider={provider}
              data-plyr-embed-id={url}
              ref={plyr}
              data-plyr-config={JSON.stringify(options)}
            />
          </div>
        </InView>
      </Suspense>
    );
  }
  return (
    <div>
      <Image {...placeholder} />
    </div>
  );
};

Video.defaultProps = {
  play: true,
};

export default Video;
