const tinyColor = require("tinycolor2");

module.exports = {
  purge: {
    content: [
      "./src/components/**/*.js",
      "./src/pageTemplates/**/*.js",
      "./src/pages/**/*.js",
      "./src/svg/**/*.svg",
      "./src/svg/**/*.js",
      "./src/pcss/**/*.pcss",
    ],
    options: {
      safelist: [
        "object-cover",
        "object-contain",
        "grid-cols-3",
        "grid-cols-2",
        "grid-cols-1",
      ],
    },
  },
  logo: 572 / 162,
  theme: {
    fontSize: {
      "3xs": `${12 / 20}rem`, // 12px on base REM = 20px
      "2xs": `${14 / 20}rem`, // 14px on base REM = 20px
      xs: `${16 / 20}rem`,
      sm: `${18 / 20}rem`,
      base: `${20 / 20}rem`,
      lg: `${22 / 20}rem`,
      xl: `${32.5 / 20}rem`,
      "1-5xl": `${35 / 20}rem`,
      "2xl": `${40 / 20}rem`,
      "3xl": `${50 / 20}rem`,
      "4xl": `${60 / 20}rem`,
      "5xl": `${70 / 20}rem`,
      "6xl": `${80 / 20}rem`,
    },
    screens: {
      xs: { max: "400px" }, // for super small screens
      sm: "750px", // bigger than most phones
      md: "900px",
      content: "1000px",
      lg: "1200px",
      xl: "1700px", // larger than 15" macbook pro
    },
    fontFamily: {
      sans: ["Inter", "sans-serif"],
    },
    // Transforms
    maxWidth: theme => {
      return {
        none: "none",
        "2xs": "16rem",
        xxs: "8rem",
        ...theme("screens"),
        breakout: "20rem",
        "breakout-content": "500px",
        article: "835px",
      };
    },
    lineHeight: {
      1: 1,
      1.2: "1.2",
      1.35: "1.35",
      1.5: "1.5",
    },
    transitionDuration: {
      166: "166ms",
      333: "333ms",
      666: "666ms",
    },
    extend: {
      minHeight: {
        "1/3-screen": "33.3vh",
        "1/2-screen": "50vh",
        "2/3-screen": "66.7vh",
      },
      height: {
        "1/2": "50%",
        "1/2-screen": "50vh",
      },
      colors: {
        transparent: "transparent",
        white: {
          DEFAULT: "#FFFFFF",
          off: "#fafafa",
          scrim: "rgba(255,255,255,.5)",
        },
        black: {
          DEFAULT: "#393d47",
          scrim: "rgba(0,0,0,.5)",
        },
        beige: {
          DEFFAULT: "#ede8dd",
        },
        blue: {
          DEFAULT: "#006ac6",
        },
        gray: {
          dark: "#393d47",
          DEFAULT: "#62687a",
          light: "#cacdd5",
          lighter: "#f3f4f5",
        },
      },
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
      width: {
        "1/4": "25%",
        "1/3": "33.33%",
        "1/2": "50%",
        "2/3": "66.67%",
        "3/4": "75%",
        "1/7": "calc( 1 / 7 * 100)%",
        "2/7": "calc( 2 / 7 * 100)%",
        "3/7": "calc( 3 / 7 * 100)%",
        "4/7": "calc( 4 / 7 * 100)%",
        "5/7": "calc( 5 / 7 * 100)%",
        "6/7": "calc( 6 / 7 * 100)%",
      },
      opacity: {
        10: ".1",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".9",
        90: ".9",
      },
      zIndex: {
        60: 60,
      },
    },
  },
  variants: {
    opacity: ["hover", "group-hover", "responsive"],
    backgroundColor: ["hover", "group-hover", "responsive"],
    textColor: ["hover", "group-hover", "responsive"],
  },
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
};
