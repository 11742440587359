import React, { useEffect, useRef, useCallback } from "react";
import { useAppState } from "@state/appState";
import { gsap, Circ } from "gsap";
import { Modal } from "@designbycosmic/cosmic-react-common-components";
import { useScrollPosition } from "@hooks";
import { useHeaderData } from "@staticQueries";
import { fadeIn, fadeOut } from "@timeline";
import { Text } from "@base";
import { Link } from "@atoms";

const hasWindow = typeof window !== "undefined";

const MobileNav = () => {
  const [{ layout }, dispatch] = useAppState();
  const { scrollY } = hasWindow ? useScrollPosition() : { scrollY: 0 };
  const { menu } = useHeaderData();
  const ul = useRef();

  const { showingMobileNav, hydrated } = layout;

  useEffect(() => {
    // Close the mobile menu when users scroll up or down
    if (showingMobileNav) {
      dispatch({ type: "layout.toggleMobileNav" });
    }
  }, [scrollY]);

  useEffect(() => {
    if (!ul.current || !showingMobileNav) {
      return;
    }

    const listItems = ul.current.querySelectorAll("li");
    gsap.fromTo(
      listItems,
      {
        duration: 0.667,
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        stagger: {
          amount: 0.2,
        },
        ease: Circ.easeOut,
      }
    );
  }, [showingMobileNav]);

  const closeModal = useCallback(() =>
    dispatch({ type: "layout.toggleMobileNav" })
  );

  // TODO: why is this hydration fix needed exactly?
  // Has something to do with browser resize hook used by
  // the modal package.
  if (!hydrated) {
    return null;
  }

  return (
    <Modal
      showing={showingMobileNav}
      setShowing={closeModal}
      showingAnimation={fadeIn}
      hidingAnimation={fadeOut}
      style={{ opacity: 0 }}
      fullScreen
      className="z-50"
      ModalClose={() => null}
    >
      <nav className="bg-black text-white w-full h-full flex items-center justify-center">
        <ul ref={ul} className="list-reset">
          {menu.map(({ uid, title, url }) => (
            <li key={uid} className="block my-8 text-center">
              <Link to={url}>
                <Text tag="span" options={{ size: "text-xl" }}>
                  {title}
                </Text>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </Modal>
  );
};

export default MobileNav;
