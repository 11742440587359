/**
 * `resolveCb` is used to transform an array of Prismic slices.
 * You will likely need to create a `case` for each slice that you define in Prismic.
 *
 * @param {object[]} body The array of slices
 *
 */

const resolveCb = body => {
  const resolved = body
    .filter(block => block.primary || block.items)
    .map(block => {
      const { id, sliceType, primary, items } = block;
      switch (sliceType) {
        case "copy":
          return {
            id,
            type: sliceType,
            content: primary.content.html,
          };
        case "image":
          return {
            id,
            type: sliceType,
            image: primary.media,
            caption: primary.caption,
            size: primary.size,
          };
        case "video_embed":
          return {
            id,
            type: sliceType,
            url: primary.mediaUrl,
            caption: primary.caption,
          };
        case "pull_quote":
          return {
            id,
            type: sliceType,
            quote: primary.quoteContent,
            author: primary.quoteAuthor,
          };
        case "quote_with_heading":
          return {
            id,
            type: sliceType,
            heading: primary.quoteHeading,
            copy: primary.quoteCopy,
          };
        case "image_with_accordian":
          return {
            id,
            type: sliceType,
            image: primary.media,
            heading: primary.accordianHeading,
            caption: primary.accordianCaption,
            content: primary.accordianContent.html,
            portrait: primary.portrait,
          };
        case "breakout_box":
          return {
            id,
            type: sliceType,
            content: primary.breakoutContent.html,
            image: primary.breakoutImage,
            caption: primary.breakoutImageCaption,
            boxHeading: primary.boxHeading,
            boxContent: primary.boxContent.html,
            swap: primary.boxPosition,
            horizontal: primary.horizontal,
          };
        case "carousel":
          return {
            id,
            type: sliceType,
            slides: items.map(slide => {
              return {
                image: slide.carouselImage,
                caption: slide.imageCaption.html,
              };
            }),
          };
        default:
          return null;
      }
    });
  return resolved.filter(block => block !== null);
};

export default resolveCb;
