import React from "react";
import propTypes from "prop-types";
import { Image, RichText, Text, Container } from "@base";
import { Button, Link } from "@atoms";

import classNames from "classnames";

const SliceFullBleedImage = ({
  image,
  heading,
  copy,
  description,
  textY,
  textX,
  bw,
  darkText,
  size,
  buttons,
}) => {
  return (
    <div
      className={classNames({
        "min-h-1/2-screen sm:min-h-2/3-screen md:min-h-screen":
          size !== "fit text" && size !== "news" && size !== "article",
        "min-h-2/3-screen": size === "news",
        "min-h-1/3-screen sm:min-1/2-screen": size === "article",
        "relative flex overflow-hidden": true,
      })}
    >
      <div className="absolute inset-0">
        {bw && (
          <div className="fade-to-black transform rotate-180 absolute bottom-0 left-0 right-0 z-10 opacity-75 h-64 min-h-1/3-screen" />
        )}
        <div className="absolute inset-0 -my-px z-0">
          <Image {...image} fill bw={bw} />
        </div>
      </div>
      <div
        className={classNames({
          "relative z-10 flex-grow flex": true,
          "text-white": !darkText,
          "text-gray": darkText,
        })}
        style={
          darkText || size === "news"
            ? null
            : { textShadow: "0 0 30px black, 0 0 40px black," }
        }
      >
        <Container
          className={classNames({
            "justify-start": textY === "top",
            "justify-center": textY === "middle",
            "justify-end": textY === "bottom",
            "items-center": textX === "center",
            "items-start": textX === "left",
            "items-end": textX === "right",
            "relative flex flex-col h-full w-full py-12 md:py-24 flex-grow": true,
          })}
        >
          <div className="max-w-sm">
            {textY === "bottom" && <div className="min-h-2/3-screen w-full" />}
            <div className="flex flex-col">
              {heading && (
                <Text
                  tag="h2"
                  className={classNames({
                    "text-center": textX === "center",
                    "text-gray-dark": darkText,
                  })}
                >
                  {heading}
                </Text>
              )}
              {(copy || description?.html) && (
                <RichText
                  html={copy || description.html}
                  size="lg"
                  className={classNames({
                    "text-center": textX === "center",
                    "mt-6": heading,
                  })}
                />
              )}
              {buttons && buttons?.[0]?.link && (
                <div className="flex flex-wrap justify-center items-center mt-6">
                  {buttons.map(b => {
                    return (
                      <Link to={b.link} className="m-2">
                        <Button bgColor="blue" text={b.text} />
                      </Link>
                    );
                  })}
                </div>
              )}
              {textY === "top" && <div className="min-h-2/3-screen w-full" />}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

SliceFullBleedImage.defaultProps = {};

SliceFullBleedImage.propTypes = {};

export default SliceFullBleedImage;
