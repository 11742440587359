/* eslint-disable react/prop-types */
import React from "react";
import propTypes from "prop-types";
import { Container, RichText, Text } from "@base";

const CbQuoteWithHeading = ({ heading, copy }) => {
  return (
    <Container size="article" className="py-4 md:py-12">
      <Text
        tag="h3"
        use="span"
        className="mt-6 mb-4 text-lg leading-1.2 text-blue font-bold uppercase tracking-wide"
      >
        {heading}
      </Text>
      <div className="my-6 border-l-2 border-blue text-gray-2 pl-8">
        <RichText html={copy.html} />
      </div>
    </Container>
  );
};

CbQuoteWithHeading.defaultProps = {};

CbQuoteWithHeading.propTypes = {};

export default CbQuoteWithHeading;
