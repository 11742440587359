import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query AssetQuery {
    prismicDownloadableAssets {
      data {
        media {
          asset {
            url
          }
        }
      }
    }
  }
`;

const dataResolver = response =>
  response.prismicDownloadableAssets.data.media.map(m => m.asset.url);

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
