import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "./ButtonBase";

const ButtonDefault = ({
  bgColor,
  className,
  inverse,
  outline,
  color,
  style,
  size,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      className={classNames(
        "flex leading-none items-center text-white rounded-full transition duration-166 ease-in-out",
        {
          [`bg-${bgColor} hover:bg-gray-dark`]: true,
          "py-2 px-3 text-xs font-semibold": size === "md",
          "py-2 px-3 text-xs": size === "sm",
          [`border border-${color} hover:bg-${color}`]: outline,
          "text-white": inverse,
        },
        className
      )}
      style={{ ...style }}
    />
  );
};

ButtonDefault.propTypes = {
  bgColor: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  size: PropTypes.string,
};

ButtonDefault.defaultProps = {
  bgColor: "blue.DEFAULT",
  className: "",
  style: {},
  size: "md",
};

export default ButtonDefault;
