import React from "react";
import propTypes from "prop-types";
import { Image, Container, Text, RichText } from "@base";
import { getTailwindColor } from "@utils";

const SliceLogoGrid = ({ heading, copy, logos, backgroundImage }) => {
  return (
    <div className="bg-beige text-white bg-black relative py-6 sm:py-12">
      {/* <div className="absolute opacity-50 inset-0 z-0">
        <Image {...backgroundImage} fill />
      </div> */}
      <div className="relative text-center z-10">
        <Container>
          {heading && <Text tag="h2">{heading}</Text>}
          {copy && <RichText html={copy} size="lg" />}
          <div className="mx-auto mt-6" style={{ maxWidth: "40rem" }}>
            <div className="-mx-3 justify-center flex flex-wrap">
              {logos.map(logo => (
                <div className="w-1/2 sm:w-1/3 flex flex-col items-center justify-center">
                  <div className="h-24 w-full" style={{ maxWidth: "15rem" }}>
                    <Image
                      {...(logo?.logo || logo)}
                      params={{
                        fit: "max",
                        "blend-color": "ffffff",
                        "blend-mode": "normal",
                        mask: (logo?.logo || logo).url.split("?")[0],
                      }}
                      transparent
                      fitHeight
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
      {/* the guts */}
    </div>
  );
};

SliceLogoGrid.defaultProps = {};

SliceLogoGrid.propTypes = {};

export default SliceLogoGrid;
