import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel, resolveImage, mergePrismicPreview } from "@dataResolvers";

const query = graphql`
  query AllPressReleasesQuery {
    allPrismicPressReleases {
      edges {
        node {
          uid
          url
          data {
            image {
              url
              dimensions {
                width
                height
              }
            }
            heading
            date(formatString: "MMM DD, YYYY")
            datetime
            short_title
            show_date
            summary {
              html
            }
            excerpt
            meta {
              title
              description
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

const resolvePressRelease = article => {
  const { uid, url, data } = article.node;
  const { image, shortTitle, showDate, meta, excerpt, ...rest } =
    keysToCamel(data);
  return {
    ...rest,
    uid,
    url,
    image: resolveImage(meta?.[0]?.image) || resolveImage(image),
    shortTitle,
    showDate: showDate !== false,
    assets: [],
    type: "pr",
  };
};

const dataResolver = response => {
  const pressReleases =
    response.allPrismicPressReleases.edges.map(resolvePressRelease);
  return pressReleases.sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
