/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
// import propTypes from "prop-types";
import { Link } from "@atoms";
import classNames from "classnames";
import { Text } from "@base";
import { useAppState } from "@state/appState";
import gsap from "gsap";

const MobileNav = ({ menu, currentPath }) => {
  const [{ layout }, dispatch] = useAppState();

  const mobileNav = useRef();
  const tl = useRef();

  useEffect(() => {
    if (!tl.current) {
      tl.current = gsap
        .timeline()
        .pause()
        .set(document.body, { overflow: null })
        .set(document.body, { overflow: "hidden" })
        .fromTo(
          mobileNav.current,
          { height: 0, display: "none" },
          { display: "flex", height: "100vh", duration: 0.2 }
        )
        .fromTo(
          mobileNav.current.children,
          { y: -20, opacity: 0 },
          { y: 0, opacity: 1, stagger: 0.05, duration: 0.1 },
          "-=.2"
        );
    }
    if (layout.showingMobileNav) {
      tl.current.play();
    }
    if (!layout.showingMobileNav) {
      tl.current.reverse();
    }
  }, [layout.showingMobileNav]);

  return (
    <nav
      ref={mobileNav}
      className="flex-col items-center justify-center hidden bg-black absolute py-12 top-0 left-0 right-0"
    >
      {menu.map(item => (
        <Link
          key={item.uid}
          to={item.url}
          className={classNames({
            "mx-4 lg:mx-6 p-1 my-2 border-b-2 border-transparent hover:border-blue": true,
            "transition duration-333 ease-in-out": true,
            "border-white":
              (currentPath.includes(item.url) && item.url !== "/") ||
              (currentPath === "/" && item.url === "/"),
          })}
        >
          <Text tag="span" className="text-white text-lg">
            {item.title}
          </Text>
        </Link>
      ))}
    </nav>
  );
};

MobileNav.defaultProps = {};

MobileNav.propTypes = {};

export default MobileNav;
