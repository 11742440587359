import { mergePrismicPreviewData } from "gatsby-source-prismic";

const hasWindow = typeof window !== "undefined";

const mergePreviewData = staticData => {
  const { __PRISMIC_PREVIEW_DATA__: previewData } = hasWindow ? window : {};

  if (previewData) {
    let merged;

    try {
      merged = mergePrismicPreviewData({ staticData, previewData });
    } catch (error) {
      throw new Error(error);
    }

    return merged || staticData;
  }

  return staticData;
};

export default mergePreviewData;
