import React from "react";
import propTypes from "prop-types";
import { Container, Text, RichText } from "@base";
import { Link } from "@atoms";

const MediaKits = ({ heading, descriptor, kits }) => {
  return (
    <div className="bg-gray-lighter py-12">
      <Container hasMorePadding>
        <div className="max-w-sm px-6 text-center mx-auto mb-6">
          <Text tag="h2" className="mb-6">
            {heading}
          </Text>
          <RichText className="text-sm" html={descriptor} />
        </div>
      </Container>
      <Container hasMorePadding>
        <ul
          className={`grid gap-6 lg:gap-12 mt-12 sm:grid-cols-${
            kits.length < 3 ? kits.length : 3
          } mb-6 ${
            kits.length === 1 ? "px-12 sm:px-24 md:px-48 lg:px-52 xl:px-64" : ""
          }`}
        >
          {kits.map(kit => {
            return (
              <li>
                <Link
                  to={kit.url}
                  download
                  className="bg-white rounded-md shadow-2xl hover:shadow-xl flex flex-col items-center justify-center text-center text-gray-dark p-6 lg:py-12 transition duration-333 h-full group"
                >
                  <Text tag="h4" className="text-center font-semibold mb-3">
                    {kit.title}
                  </Text>
                  {kit.description && (
                    <div
                      className="mb-3 text-xs"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: kit.description }}
                    />
                  )}
                  <div className="text-xs text-white rounded-full bg-blue px-3 py-2 transition font-bold duration-333 group-hover:bg-gray-dark">
                    {kit.linkText}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </Container>
    </div>
  );
};

MediaKits.defaultProps = {};

MediaKits.propTypes = {};

export default MediaKits;
