import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { gsap } from "gsap";
import { useAppState } from "@state/appState";
import { Button } from "@atoms";

const MobileNavButton = ({ className, style }) => {
  const [{ layout }, dispatch] = useAppState();

  const defaultRef = useRef();
  const menuRef = useRef();
  const closeRef = useRef();

  useEffect(() => {
    if (layout.showingMobileNav) {
      gsap.to(defaultRef.current, {
        duration: 0.333,
        opacity: 1,
        strokeWidth: 2,
        fill: "none",
        morphSVG: closeRef.current,
      });
    } else {
      gsap.to(defaultRef.current, {
        duration: 0.333,
        opacity: 1,
        strokeWidth: 0,
        fill: "currentColor",
        morphSVG: menuRef.current,
      });
    }
  }, [layout.showingMobileNav]);

  const handleClick = useCallback(() => {
    dispatch({ type: "layout.toggleMobileNav" });
  });

  return (
    <Button
      variant="unStyled"
      className={`MobileMenuToggle ${className}`}
      style={{ ...style, width: 64, height: 64 }}
      onClick={handleClick}
    >
      <svg
        width={64}
        height={64}
        viewBox="0 0 64 64"
        stroke="currentColor"
        strokeLinecap="round"
      >
        {/* Start with a simple dot that will morph into
        the appropriate shape based on screen size and app state */}
        <path ref={defaultRef} d="M33 30.1052H31V32.1052H33V30.1052Z" />

        {/* The rest of these shapes are hidden, but included in the DOM so GSAP can
        reference their paths, which it will use to morph the defaultRef */}
        <path
          ref={menuRef}
          d="M43 21H21C20.4477 21 20 21.4477 20 22C20 22.5523 20.4477 23 21 23H43C43.5523 23 44 22.5523 44 22C44 21.4477 43.5523 21 43 21Z M43 39.2104H21C20.4477 39.2104 20 39.6582 20 40.2104C20 40.7627 20.4477 41.2104 21 41.2104H43C43.5523 41.2104 44 40.7627 44 40.2104C44 39.6582 43.5523 39.2104 43 39.2104Z M43 30.1052H21C20.4477 30.1052 20 30.5529 20 31.1052C20 31.6575 20.4477 32.1052 21 32.1052H43C43.5523 32.1052 44 31.6575 44 31.1052C44 30.5529 43.5523 30.1052 43 30.1052Z"
          style={{ display: "none" }}
        />
        <path
          ref={closeRef}
          d="M42 22L22 42M42 42L22 22.0001"
          style={{ display: "none" }}
        />
      </svg>
    </Button>
  );
};

MobileNavButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

MobileNavButton.defaultProps = {
  className: false,
  style: null,
};

export default MobileNavButton;
