import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Link } from "@atoms";
import { Text, Icon } from "@base";

const Share = ({ url: _url }) => {
  const url = `${process.env.GATSBY_FRONTEND_URL}${encodeURIComponent(_url)}`;
  return (
    <div className="flex items-center my-8">
      <Text
        tag="span"
        className="uppercase text-gray-3 font-bold tracking-wider mr-4"
      >
        Share
      </Text>

      <div className="flex items-center space-x-5 text-blue">
        <Link to={`https://www.facebook.com/sharer/sharer.php?${url}`}>
          <Icon name="facebook2" className="w-4 h-4" />
        </Link>

        <Link to={`https://twitter.com/intent/tweet?url=${url}`}>
          <Icon name="twitter" className="w-4 h-4" />
        </Link>

        <Link to={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}>
          <Icon name="linkedin2" className="w-4 h-4" />
        </Link>
      </div>
    </div>
  );
};

Share.defaultProps = {};

Share.propTypes = {};

export default Share;
