import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview, resolveImage, keysToCamel } from "@dataResolvers";

const query = graphql`
  query MetaDataQuery {
    prismicGlobals {
      id
      data {
        meta {
          default_title
          default_description
          default_image {
            url
            dimensions {
              height
              width
            }
          }
          author
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { meta } = prismicGlobals.data;
  const { default_image, ...rest } = meta[0];

  const resolved = {
    ...rest,
    defaultImage: resolveImage(default_image),
  };

  return keysToCamel(resolved);
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
