import React, { useState, useEffect, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import { InView } from "@designbycosmic/cosmic-react-common-components";

const Iframe = forwardRef(
  (
    { src, ratio, height, backgroundColor, className, style, ...rest },
    forwardedRef
  ) => {
    const [inView, setInvew] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const handleIframeLoad = () => setIframeLoaded(true);

    // If no ref forwarded, then register one for the iframe
    const iframe = forwardedRef || useRef();

    useEffect(() => {
      if (inView) {
        iframe.current.addEventListener("load", handleIframeLoad, true);
      }

      return iframe.current.removeEventListener("load", handleIframeLoad);
    }, [inView]);

    return (
      <InView
        onEnter={() => setInvew(true)}
        // Un-comment this to remove iframe src from DOM when out of view
        // This can be used to stop a playing video when scrolling out of view.
        // onExit={() => setInvew(false)}
        observerOptions={{
          threshold: 0.1,
          rootMargin: "5% 0%",
        }}
        className={className}
      >
        <div
          className="relative w-full transition duration-333"
          style={{
            paddingBottom: height || `${(1 / ratio) * 100}%`,
            backgroundColor: inView ? "transparent" : backgroundColor,
          }}
        >
          <iframe
            {...rest}
            ref={iframe}
            title="iframe"
            className="absolute inset-0 z-10 transition duration-666 ease-in-out"
            width="100%"
            height="100%"
            src={inView ? src : ""}
            style={{
              ...style,
              opacity: iframeLoaded && inView ? 1 : 0,
            }}
          />
        </div>
      </InView>
    );
  }
);

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
  ratio: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Iframe.defaultProps = {
  backgroundColor: undefined,
  ratio: 16 / 9,
  height: null,
  className: "",
  style: {},
};

export default Iframe;
