import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Text } from "@base";
import { Link, Icon } from "@atoms";
import { useHeaderData } from "@staticQueries";
import { useAppState } from "@state/appState";
import { useScrollPosition } from "@hooks";
import { getTailwindColor } from "@utils";
import { MobileNav } from "@molecules";
import MobileNavButton from "./MobileNavButton";

const hasWindow = typeof window !== "undefined";

const Header = ({ currentPath }) => {
  const { menu } = useHeaderData();
  const [{ layout }] = useAppState();
  const headerRef = useRef();

  const { scrollY, scrollingUp } = hasWindow
    ? useScrollPosition()
    : { scrollY: 0, scrollingUp: false };

  const height = layout.breakpoints.md ? 100 : 64;
  const headerVisible = scrollY <= 50 || scrollingUp || layout.breakpoints.xl;

  return (
    <header
      ref={headerRef}
      className={classNames({
        "sticky top-0 left-0 right-0 z-50 flex items-center transition duration-666 ease-in-out": true,
        "opacity-100 pointer-events-auto": headerVisible,
        "opacity-0 pointer-events-none": !headerVisible,
      })}
      style={{
        height,
        marginBottom: height * -1,
        backgroundColor:
          (scrollY > 0 && scrollingUp) || (scrollY > 0 && layout.breakpoints.xl)
            ? getTailwindColor("black.default")
            : "transparent",
      }}
    >
      <div className="fade-to-black opacity-50 h-24 absolute inset-0 z-0" />
      <Container
        className="relative z-10 flex items-center"
        hasPadding={layout.breakpoints.md}
      >
        <Link to="/" className="ml-4 md:ml-0 text-white relative z-40">
          <Icon name="jobyIcon" fitHeight className="h-8" />
        </Link>

        <nav className="hidden sm:flex items-end ml-auto">
          <div className="flex flex-row items-center h-8">
            {menu.map(item => (
              <Link
                key={item.uid}
                to={item.url}
                className={classNames({
                  "mx-4 lg:mx-6 px-px border-b-2 border-transparent hover:border-blue": true,
                  "transition duration-333 ease-in-out text-sm": true,
                  "border-white":
                    (currentPath.includes(item.url) && item.url !== "/") ||
                    (currentPath === "/" && item.url === "/"),
                })}
              >
                <Text tag="span" className="leading-1.5 text-white">
                  {item.title}
                </Text>
              </Link>
            ))}
          </div>
        </nav>
        <MobileNav menu={menu} currentPath={currentPath} />
        <MobileNavButton className="sm:hidden ml-auto text-white relative z-40" />
      </Container>
    </header>
  );
};

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
};

export default Header;
